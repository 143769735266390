footer {
	background-color:$gray-dark;
	position: absolute;
  	left: 0;
  	bottom: 0;
  	width: 100%;
  	height: 48px;

	.copyright {
		color:$white;
		line-height:48px;
	}
}

.content {
 	padding-bottom: 48px;
}
