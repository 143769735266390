.contact-container {
	background-color:$white;
	position: relative;
	z-index: 1;

	&:not(:last-child) {
		margin-bottom: 20px;
	}
}

.contact-form-block {
	.gform_confirmation_wrapper  {
		.gform_confirmation_message {
			text-align: center;
			margin-bottom: 20px;
		}
	}
	.gform_wrapper {
		@include no-mobile {
			padding:0 35px;
		}

		.validation_error {
			text-align:center;
			margin-bottom: 20px;
		}
		.gfield_description.validation_message {
			margin-left: 2px;
			margin-top:5px;
		}
		

		.gform_body {
			ul {
				list-style:none;
				padding:0;
				margin:0 -10px;
				li {
					display: block;
					padding-left:10px;
					padding-right: 10px;
					margin-bottom: 20px;

					label {
						display: none;
						margin:0;
					}

					input {
						height: 34px;
					}

					input, textarea {
						width: 100%;
						border: 1px solid $gray-dark;
					    font-size: $font-xs;
					    color: $gray-dark;
					    padding: 6px 12px;
					    background-color: $white;

					    &:focus {
					    	outline: none;
					    	border-color: $yellow;
					    	 @include placeholder() {
					    	 	color: $yellow;
					    	 }
					    }

					    @include placeholder() {
							color:$gray-dark;
							text-transform: uppercase;
						}
					}
				}

				li:nth-child(1), li:nth-child(2),
				li:nth-child(3), li:nth-child(4) {

					@include no-mobile {
						float: left;
						width:50%;
					}
				
				}
			}
		}

		.gform_footer {
			input[type="submit"] {
				float: right;
				margin-top: 30px;
    			margin-bottom: 50px;
    			@include border-radius(0);
			    color: #fff;
			    font-size: $font-md;
			    line-height: 14px;
			    text-transform: uppercase;
			    padding: 12px 15px;
			    min-width: 100px;
			    border: 1px solid $gray-dark;
			    background-color: $gray-dark;

			    &:hover, &:focus {
			    	background-color: $yellow;
			    	border-color: $yellow;
			    	outline: none;
			    }
			}
		}

	}
}

.contact-info-block {
	padding:20px;
	text-align:center;

	.contact-info {
		font-size:$font-sm;
		line-height:16px;
		span {
			color:$gray-dark;
			margin-right:3px;
		}
		a {
			color:$gray;

			&:hover, &:focus, &:active {
				color:$yellow;
			}
		}
	}
}