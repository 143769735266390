$standartDistance: 20px;
$transitionSpeed : 0.3s;

//breakpoints

$mobile-width: 767px;
$tablet-width-min: 768px;
$tablet-width-max: 991px;
$tablet-landscape:1024px;
$desktop-menu:1025px;
$desktop-xs-min: 992px;
$desktop-xs-max: 1279px;
$desktop-sm-min: 1280px;
$desktop-sm-max:1366px ;
$desktop-md-min:1367px ;
$desktop-md-max:1439px ;
$desktop-lg-min: 1440px;
$desktop-lg-max: 1599px;
$desktop-xl-min:1600px;
$desktop-xl-max:1919px;
$desktop-xxl-min:1920px;

$mobile-xs:320px;
$mobile-sm-min:321px;
$mobile-lg-min:479px;
$mobile-lg-max:480px;
$mobile-xl-min:599px;
$mobile-xl-max:600px;

//colors
$black: #000;
$white: #fff;
$gray-dark: #232323;
$yellow: #eaaa2a;
$gray: #474747;

$font-xs: 0.92em; //12px
$font-sm: 1em; //13px
$font-md: 1.08em; //14px
$font-lg: 1.54em; //20px
$font-title: 1.3em;
$font-xl: 3.85em; //50px
$font-xxl: 21em; //273px

//fonts 
$font-default: 'Assistant-Regular';
$font-bold: 'Assistant-Bold';
$font-heading:'Antonio-Bold';

$font-default-ge: 'BPG Nateli';
$font-bold-ge: 'BPG ExtraSquare Mtavruli';
$font-heading-ge:'BPG ExtraSquare Mtavruli';

$font-default-ru: 'Roboto-Light';
$font-bold-ru: 'Roboto-Bold';
$font-heading-ru:'Oswald-SemiBold';
