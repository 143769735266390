.container {

	@include no-mobile {
		padding-left:0;
		padding-right:0;
	}
	
}
.row {
	margin-left:-10px;
	margin-right:-10px;
}
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, 
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, 
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, 
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
	padding-left: 10px;
	padding-right: 10px;
}

/*****************************************************Global ********************************************/
html {
	min-height: 100%;
	position:relative;
	
}

body {
	background: $white;	
	font-size:13px;
	line-height:17px;
	color:$gray;
	width: 100%;	  
}

html {
    height: 100%;
}
body {
	position: relative;
  	min-height: 100%;
}

[class*='icon-']:before{
	display: inline-block;
   font-family: 'gomedia';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-facebook:before{content:'\0041';}
.icon-youtube:before{content:'\0042';}
.icon-vimeo:before{content:'\0043';}

[v-cloak] {
  display: none;
}

.container-small {
	@include desktop {
		width:638px;

		.container-big {
			margin-left:-111px;
		}
	}
	.container-big {
		
		padding-left: 0;
		padding-right: 0;
		
		@include desktop {
			margin-left:-110px;
		}
	}
	
}
.container-big {

	@include desktop {
		width:860px;
	}
}

h1,h2,h3,h4,h5,h6 {
	margin-top:0;
	margin-bottom:20px;
}

a {
	color:$gray-dark;
	font-size:$font-sm;

	&:hover, &:focus{
		color:$yellow;
		outline:none;
	}
	 
}

a + a {
	&:before {
		content: '|';
		margin-left:5px;
		margin-right:5px;
		color:$gray;
			
	}
}
ul {
	margin-bottom:20px;
}

p {
	padding:0 35px;
	margin: 0 0 20px 0;
	font-size:$font-sm;
	line-height:17px;
	color:$gray;

	@include mobile {
		padding:0;

		img {
			margin:0;
			width:100%;
			max-width:100%;
		}
	}

	img {

		margin:0 0 20px -35px;
		width: calc(100% + 70px);
		height:auto;
		max-width: calc(100% + 70px);

		@include mobile {
			width:100%;
			height:auto;
			max-width:100%;
			margin: 0 0 20px 0;
		}
	
			
	}

}


p.video-holder {
	margin:0 0 20px 0;
    position: relative;
    padding: 0 0 56.25% 0;
    height: 0; 
    overflow: hidden;
}
 
p.video-holder iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.bg-img {
	margin-top:30px;
	margin-bottom: 30px;

	.img-holder {
		@include bg-center(300px);
	}
}
.container-small {
	 .page-title {
			margin-top:20px;
	}
}
.preloader {
 margin-top:40px;
 margin-bottom: 60px;

 .preloader-icon {
  width:30px;
  height:30px;
  background-image:url(../../dist/images/icons/preloader.gif);
  background-size:100% auto;
  background-repeat: no-repeat;
  @include horizontal-center();
  display:block;
 }
}

.no-result {
	text-align:center;
	font-size:$font-lg;
}


html[lang="en-US"] body {

	font-family: $font-default;

	a {
		font-family: $font-bold;
	}

	p {
		font-family: $font-default;
	}

	h1, h2, h3,h4,h5,h6 {
		font-family: $font-bold;
	}
	
	input[type="submit"] {
		font-family: $font-bold;
	}

	.contact-form-block {
		input, textarea {
			@include placeholder {
				font-family: $font-bold;
			}
		}
	}
	

	.main-menu-list, .submenu, .school-menu-list, .mobile-languages-list {
		&>li {
			&>a {
				font-family:$font-bold;
			}
		}
	}


	.dropdown-bordered {
		a.dropdown-toggle {
			font-family:$font-bold;
		}
		.dropdown-menu {
			&>li {
				&>a {
					font-family:$font-bold;
				}
			}
		}
	}
	.dropdown-bordered.languages-dropdown {
		a.dropdown-toggle {
			font-family:$font-bold;
		}
		.dropdown-menu.languages-list {
			&>li {
				&>a {
					font-family:$font-bold;
				}
			}
		}
	}

	.filter {
		.dropdown {
			.selected-tag {
				font-family: $font-bold;
			}
			.dropdown-menu {
				&>li {
					&>a {
						font-family:$font-bold;
					}
				}
			}
		}
		&.author-filter {
			.dropdown {
				.dropdown-menu {
					&>li {
						&>a {
							font-family:$font-default;
						}
					}
				}
			}
			
		}
	}

	.school-title, .school-title-with-subtitle { 
		h1, h1.title {
			font-family:$font-bold;
		}
	}

	.show-more-btn, .btn  {
		font-family:$font-bold;
	}

	.item-heading, .item-heading-with-subtitle {
		.item-title {
			font-family:$font-bold;
		}
		.item-subtitle {
			font-family:$font-default;
		}
	}

	.full-width-col {
		.full-width-subtitle {
			font-family: $font-heading;
		}
	}

	.video-block {
		.video-title {
			font-family:$font-heading;
		}
	}

	.flexbox-slider {
		.flexbox-slide {
			.slider-title {
				h1 {
					font-family:$font-heading;
				}
			}
		}
	}

	.trainers-grid {
		.trainer-name {
			font-family: $font-heading;
		}
	}

	.class-block {
		.class-title {
			font-family: $font-bold;
		}

		.class-list {
			li {
				a {
					font-family: $font-bold;
				}
			}
		}
	}

	.contact-form-block {
		.contact-form {
			.form-control {
				font-family:$font-bold;
			}
		}
	}

	.contact-info-block {
		.contact-info {
			span {
				font-family:$font-bold;
			}
		}
	}

	.block-404 {
		.box-404 {
			.txt-404 {
				font-family:$font-heading;
			}

			.box-404-info {
				.not-found-txt {
					font-family:$font-heading;
				}
			}
		}
	}

	footer {
		.copyright {
			font-family: $font-default;
		}
	}
}

html[lang="ru-RU"] body {

	font-family: $font-default-ru;

	p {
		font-family: $font-default-ru;
	}

	a {
		font-family: $font-bold-ru;
	}


	h1, h2, h3,h4,h5,h6 {
		font-family: $font-bold-ru;
	}

	input[type="submit"] {
		font-family: $font-bold-ru;
	}

	.contact-form-block {
		input, textarea {
			@include placeholder {
				font-family: $font-bold-ru;
			}
		}
	}
	.video-info-box {
		.video-title {
			font-size: 3.5em;
		}
	}


	.main-menu-list, .submenu, .school-menu-list {
		&>li {
			&>a {
				font-family:$font-bold-ru;
			}
		}
	}

	.school-menu-list {
		&>li {
			&>a {
				text-transform: capitalize !important;
				font-size: $font-sm !important;
			}
		}
	}

	.dropdown-bordered {
		a.dropdown-toggle {
			font-family:$font-bold-ru;
		}
		.dropdown-menu {
			&>li {
				&>a {
					font-family:$font-bold-ru;
				}
			}
		}
	}
	.dropdown-bordered.languages-dropdown {
		a.dropdown-toggle {
			font-family:$font-bold;
		}
		.dropdown-menu.languages-list {
			&>li {
				&>a {
					font-family:$font-bold;
				}
			}
		}
	}

	.mobile-languages-list {
		&>li {
			&>a {
				font-family:$font-bold;
			}
		}
	}

	.filter {
		.dropdown {
			.selected-tag {
				font-family: $font-bold-ru;
			}
			.dropdown-menu {
				&>li {
					&>a {
						font-family:$font-bold-ru;
					}
				}
			}
		}
		&.author-filter {
			.dropdown {
				.dropdown-menu {
					&>li {
						&>a {
							font-family:$font-default-ru;
						}
					}
				}
			}
			
		}
	}

	.school-title, .school-title-with-subtitle { 
		h1, h1.title {
			font-family:$font-bold-ru;
		}
	}

	.show-more-btn, .btn  {
		font-family:$font-bold-ru;
	}

	.item-heading, .item-heading-with-subtitle {
		.item-title {
			font-family:$font-bold-ru;
		}
		.item-subtitle {
			font-family:$font-default-ru;
		}
	}

	.full-width-col {
		.full-width-subtitle {
			font-family: $font-heading-ru;
		}
	}

	.video-block {
		.video-title {
			font-family:$font-heading-ru;
		}
	}

	.flexbox-slider {
		.flexbox-slide {
			.slider-title {
				h1 {
					font-family:$font-heading-ru;
				}
			}
		}
	}

	.trainers-grid {
		.trainer-name {
			font-family: $font-heading-ru;
		}
	}

	.class-block {
		.class-title {
			font-family: $font-bold-ru;
		}

		.class-list {
			li {
				a {
					font-family: $font-bold-ru;
				}
			}
		}
	}

	.contact-form-block {
		.contact-form {
			.form-control {
				font-family:$font-bold-ru;
			}
		}
	}

	.contact-info-block {
		.contact-info {
			span {
				font-family:$font-bold-ru;
			}
		}
	}

	.block-404 {
		.box-404 {
			.txt-404 {
				font-family:$font-heading;
			}

			.box-404-info {
				.not-found-txt {
					font-family:$font-heading-ru;

					@include mobile {
						font-size: 2em;
					}
				}
			}
		}
	}

	footer {
		.copyright {
			font-family: $font-default;
		}
	}
}

html[lang="ka-GE"] body {

	font-family: $font-default-ge;

	p {
		font-family: $font-default-ge;
		font-size: $font-md;
	}

	a {
		font-family: $font-default-ge;
		font-weight: bold;
	}


	h1, h2, h3,h4,h5,h6 {
		font-family: $font-bold-ge;
		font-weight: bold;
	}

	input[type="submit"] {
		font-family: $font-bold-ge;
	}

	.contact-form-block {
		input, textarea {
			@include placeholder {
				font-family: $font-bold-ge;
			}
		}
	}
	
	.video-info-box {
		.video-title {
			font-size: 3.2em;
		}
	}

	.main-menu-list, .submenu, .school-menu-list {
		&>li {
			&>a {
				font-family:$font-bold-ge;
			}
		}
	}

	.main-menu-list {
		&>li {
			&>a {
				font-weight: bold;
			}
		}
	}
	.dropdown-bordered {
		a.dropdown-toggle {
			font-family:$font-bold-ge;
		}
		.dropdown-menu {
			&>li {
				&>a {
					font-family:$font-bold-ge;
				}
			}
		}
	}
	.dropdown-bordered.languages-dropdown {
		a.dropdown-toggle {
			font-family:$font-bold;
		}
		.dropdown-menu.languages-list {
			&>li {
				&>a {
					font-family:$font-bold;
				}
			}
		}
	}
	
	.mobile-languages-list {
		&>li {
			&>a {
				font-family:$font-bold;
			}
		}
	}
	
	.filter {
		&.select-filter {
			.dropdown {
				.selected-tag {
					font-family: $font-bold-ge;
				}
				.dropdown-menu {
					&>li {
						&>a {
							font-family:$font-bold-ge;
						}
					}
				}
			}
		}
		
		&.author-filter {
			.dropdown {
				.dropdown-menu {
					&>li {
						&>a {
							font-family:$font-default-ge;
						}
					}
				}
			}
			
		}
	}
	.school-title, .school-title-with-subtitle { 
		h1, h1.title {
			font-family:$font-bold-ge;
		}
	}

	.show-more-btn, .btn  {
		font-family:$font-bold-ge;
	}

	.item-heading, .item-heading-with-subtitle {
		.item-title {
			font-family:$font-bold-ge;
			max-height: 34px;
		}
		.item-subtitle {
			font-family:$font-default-ge;
		}
	}

	.full-width-col {
		.full-width-title {
			font-family: $font-heading-ge;
			font-size: $font-title !important;
		}
		.full-width-subtitle {
			font-family: $font-default-ge;
			font-size: $font-md !important;
		}
	}

	.video-block {
		.video-title {
			font-family:$font-heading-ge;
			@include mobile {
				font-size: 2.5em;
			}
		}
	}

	.flexbox-slider {
		.flexbox-slide {
			.slider-title {
				h1 {
					font-family:$font-heading-ge;
				}
			}
		}
	}

	.trainers-grid {
		.trainer-name {
			font-family: $font-heading-ge;
		}
	}

	.class-block {
		.class-title {
			font-family: $font-bold-ge;
		}

		.class-list {
			li {
				a {
					font-family: $font-default-ge;
				}
			}
		}
	}

	.contact-form-block {
		.contact-form {
			.form-control {
				font-family:$font-bold-ge;
			}
		}
	}

	.contact-info-block {
		.contact-info {
			span {
				font-family:$font-bold-ge;
			}
		}
	}

	.block-404 {
		.box-404 {
			.txt-404 {
				font-family:$font-heading;
			}

			.box-404-info {
				.not-found-txt {
					font-family:$font-heading-ge;
					font-size:2.1em;

					@include mobile {
						font-size: 1.5em;
					}
				}
			}
		}
	}

	footer {
		.copyright {
			font-family: $font-default;
		}
	}
}