.block-404 {
	position:relative;

	.box-404 {
		width:510px;
		height:350px;
		@include gradient-overlay(rgba(255,255,255,0),rgba(255,255,255,1));
		position: absolute;
		@include horizontal-center();
		top:50px;

		@include mobile {
			width: calc(100% - 30px);
			height:auto;
		}

		.txt-404 {
			font-size:$font-xxl;
			line-height:273px;
			text-align:center;
			color:$gray-dark;

			@include mobile {
				font-size: 15em;
			}
		}

		.box-404-info {
			position:absolute;
			width:100%;
			bottom:20px;
			text-align:center;

			.not-found-txt	{
				font-size:$font-xl;
				color:$gray-dark;
				text-transform:capitalize;
				margin-bottom:30px;

				@include mobile {
					font-size: 3em;
				}
			}

			.go-back-txt {
				font-size:$font-sm;
				line-height:13px;
				margin-bottom:30px;
				text-transform:capitalize;
			}
		}
	}
}