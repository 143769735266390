.dropdown-bordered {
	a.lang {
		border:1px solid $gray-dark;
		text-transform:uppercase;
		font-size:$font-xs;
		line-height:12px;
		height:30px;
		padding:8px 30px 8px 10px;
		display: inline-block;

		&:hover, &:focus {
			text-decoration: none;
		}
	}

	a.dropdown-toggle {
		display:block;
		cursor: pointer;
		color:$gray-dark;
		display: block;
	    color: #232323;
	    border: 1px solid $gray-dark;
	    text-transform: uppercase;
	    font-size:$font-xs;
	    line-height: 12px;
	    height: 30px;
	    padding: 8px 30px 8px 10px;
		&:after {
			content:'';
			background-image:url(../../dist/images/icons/arrow.svg);
			display:block;
			width:10px;
			height:5px;
			position:absolute;
			right:10px;
			top:12px;
		}
		&:hover {
		text-decoration:none;
		}
		&[aria-expanded="true"] {
			background-color:$gray-dark;
			color:$white;
			&:after {
				background-image:url(../../dist/images/icons/arrow-white.svg);
			}
			
		}
	}
	.dropdown-menu {
		min-width:auto;
		width:100%;
		margin:0;
		padding:0;
		border:1px solid $gray-dark;
		@include border-radius(0);
		box-shadow:none;

		&>li{
			height:30px;
			padding:9px 0;
			&:not(:last-child) {
				border-bottom:1px solid $gray-dark;
			}

			&>a {
				text-transform:uppercase;
				color:$gray-dark;
				font-size:$font-xs;
				line-height:12px;
				padding:0;
				text-align:center;

				&:hover, &:focus {
					background-color:transparent;
					color:$yellow;
				}
			}
		}
	}
}
.filters {
	padding:30px 35px;

	@include mobile {
		padding:30px 0;
	}
	

	.filter {
		float: left;
		margin-right: 20px;

		@include tablet {
			width: 33%;
			padding:0 10px;
			margin:0;
		}

		@include mobile-xl-lg {
			width: 33%;
    		padding:0 5px;
    		margin:0;
		}

		@include mobile-lg {
			&:nth-child(1), &:nth-child(2) {
				width:calc(50% - 5px);
				float:left;
				margin:0;
			}

			&:nth-child(2) {
				width:calc(50% - 5px);
				float: right;
			}
			
		}


		@include mobile-sm-xs {
			&:nth-child(1), &:nth-child(2) {
				width: 100%;
			}

			&:nth-child(2) {
				margin-top: 10px;
	
			}
		}

		
		&.select-filter {

			@include desktop {
				width:calc(33% - 20px);
			}
		
			.dropdown {
				.dropdown-toggle {
					@include border-radius(0);
					border:1px solid $gray-dark;
					padding:0 24px 0 8px;
					height: 28px;
					button.clear {
						display: none;
					}

					input.form-control {
						width: auto !important;
						height: 28px;
						padding:0;
						position: relative;
					}
				}

				.selected-tag {
					margin:0;
					padding:0;
					color:$gray-dark;
					text-transform: uppercase;
					font-size:$font-xs;
					line-height: 28px;
					border:none;
					@include border-radius(0);
					position: absolute;
				}

				.open-indicator {
					height: 5px;
					bottom: 0;	
					@include vertical-center();
					right:8px;

					&:before {
						border:none;
						background-image: url(../../dist/images/icons/arrow.svg);
						width:10px;
						height: 5px;
						transform: rotate(0deg);
					}
				}

				.dropdown-menu {
					background-color: $white;
					max-height: 121px !important;
					overflow-y: scroll;
					border:1px solid $gray-dark;
					@include border-radius(0);
					padding: 0;
					min-width: auto;

					&::-webkit-scrollbar-track {
						//-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
						@include border-radius(10px);
						background-color: $white;
					}

					&::-webkit-scrollbar {
						width: 4px;
						background-color: $white;
					}

					&::-webkit-scrollbar-thumb {
						@include border-radius(10px);
						background-color: rgba(0,0,0,0.15);
					}
					&>li {
						height: 30px;
    					&:not(:last-child) {
    						border-bottom: 1px solid $gray-dark;
    					}

    					&>a {
    						text-transform: uppercase;
						    color: $gray-dark;
						    font-size: $font-xs;
						    line-height: 30px;
						    padding: 0;
						    text-align: center;
						    font-weight: normal !important;
    					}	

    					&.highlight {
    						&>a {
    							background: transparent;
    						}
    					}
						
    					&:hover {
							background: transparent;
							&>a {
    							color: $yellow;
    						}
    					}
					}
				}

				&.open {
				
					.open-indicator:before {
						transform: rotate(180deg);
					}

					.selected-tag {
						opacity:1;
						position: absolute;
					}
				}
			}
		}

		&.unsearchable-filter {
			.dropdown {
				.dropdown-toggle {
					input.form-control {
						cursor: pointer;
						color: transparent !important;
						  -webkit-user-select: none;
						  -moz-user-select: none;
						  -ms-user-select: none;
						  -o-user-select: none;
						  user-select: none;

						&:focus {
							outline: none;
						}
					}
				}

			}
		}

		&.author-filter {

			@include mobile-lg {
				width:100%;
				margin-top: 10px;
			}

			@include mobile-sm-xs {
				width:100%;
				margin-top: 10px;
			}
			.dropdown {
				.dropdown-toggle {

					input.form-control {
						color: inherit !important;
					}
				}
				.dropdown-menu {
					&>li {
						border: none !important;
						padding:5px 10px;
						height:auto;
						&>a {
							line-height: 14px;
							text-transform: capitalize;
							text-align: left;
							@include text-line(1);
						}
					}
				}

				&.open {
					.selected-tag {
						opacity:0.5;
					}
				}
			}
		}
	}

	
}