@font-face {
  font-family: 'Antonio-Bold';
  src: url('../../dist/fonts/Antonio-Bold/Antonio-Bold.eot');
  src: url('../../dist/fonts/Antonio-Bold/Antonio-Bold.woff2') format('woff2'),
       url('../../dist/fonts/Antonio-Bold/Antonio-Bold.woff') format('woff'),
       url('../../dist/fonts/Antonio-Bold/Antonio-Bold.ttf') format('truetype'),
       url('../../dist/fonts/Antonio-Bold/Antonio-Bold.svg#Antonio-Bold') format('svg'),
       url('../../dist/fonts/Antonio-Bold/Antonio-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Assistant-Bold';
  src: url('../../dist/fonts/Assistant-Bold/Assistant-Bold.eot');
  src: url('../../dist/fonts/Assistant-Bold/Assistant-Bold.woff2') format('woff2'),
       url('../../dist/fonts/Assistant-Bold/Assistant-Bold.woff') format('woff'),
       url('../../dist/fonts/Assistant-Bold/Assistant-Bold.ttf') format('truetype'),
       url('../../dist/fonts/Assistant-Bold/Assistant-Bold.svg#Assistant-Bold') format('svg'),
       url('../../dist/fonts/Assistant-Bold/Assistant-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Assistant-Regular';
  src: url('../../dist/fonts/Assistant-Regular/Assistant-Regular.eot');
  src: url('../../dist/fonts/Assistant-Regular/Assistant-Regular.woff2') format('woff2'),
       url('../../dist/fonts/Assistant-Regular/Assistant-Regular.woff') format('woff'),
       url('../../dist/fonts/Assistant-Regular/Assistant-Regular.ttf') format('truetype'),
       url('../../dist/fonts/Assistant-Regular/Assistant-Regular.svg#Assistant-Regular') format('svg'),
       url('../../dist/fonts/Assistant-Regular/Assistant-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'BPG ExtraSquare Mtavruli';
    src: url('../../dist/fonts/BPGExtraSquareMtavruli/BPGExtraSquareMtavruli.eot');
    src: url('../../dist/fonts/BPGExtraSquareMtavruli/BPGExtraSquareMtavruli.eot?#iefix') format('embedded-opentype'),
        url('../../dist/fonts/BPGExtraSquareMtavruli/BPGExtraSquareMtavruli.woff2') format('woff2'),
        url('../../dist/fonts/BPGExtraSquareMtavruli/BPGExtraSquareMtavruli.woff') format('woff'),
        url('../../dist/fonts/BPGExtraSquareMtavruli/BPGExtraSquareMtavruli.ttf') format('truetype'),
        url('../../dist/fonts/BPGExtraSquareMtavruli/BPGExtraSquareMtavruli.svg#BPGExtraSquareMtavruli') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BPG Nateli';
    src: url('../../dist/fonts/BPGNateli/BPGNateli.eot');
    src: url('../../dist/fonts/BPGNateli/BPGNateli.eot?#iefix') format('embedded-opentype'),
        url('../../dist/fonts/BPGNateli/BPGNateli.woff2') format('woff2'),
        url('../../dist/fonts/BPGNateli/BPGNateli.woff') format('woff'),
        url('../../dist/fonts/BPGNateli/BPGNateli.ttf') format('truetype'),
        url('../../dist/fonts/BPGNateli/BPGNateli.svg#BPGNateli') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Oswald-SemiBold';
    src: url('../../dist/fonts/Oswald-SemiBold/Oswald-SemiBold.eot');
    src: url('../../dist/fonts/Oswald-SemiBold/Oswald-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../../dist/fonts/Oswald-SemiBold/Oswald-SemiBold.woff2') format('woff2'),
        url('../../dist/fonts/Oswald-SemiBold/Oswald-SemiBold.woff') format('woff'),
        url('../../dist/fonts/Oswald-SemiBold/Oswald-SemiBold.ttf') format('truetype'),
        url('../../dist/fonts/Oswald-SemiBold/Oswald-SemiBold.svg#Oswald-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../../dist/fonts/Roboto-Bold/Roboto-Bold.eot');
    src: url('../../dist/fonts/Roboto-Bold/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../dist/fonts/Roboto-Bold/Roboto-Bold.woff2') format('woff2'),
        url('../../dist/fonts/Roboto-Bold/Roboto-Bold.woff') format('woff'),
        url('../../dist/fonts/Roboto-Bold/Roboto-Bold.ttf') format('truetype'),
        url('../../dist/fonts/Roboto-Bold/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url('../../dist/fonts/Roboto-Light/Roboto-Light.eot');
    src: url('../../dist/fonts/Roboto-Light/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('../../dist/fonts/Roboto-Light/Roboto-Light.woff2') format('woff2'),
        url('../../dist/fonts/Roboto-Light/Roboto-Light.woff') format('woff'),
        url('../../dist/fonts/Roboto-Light/Roboto-Light.ttf') format('truetype'),
        url('../../dist/fonts/Roboto-Light/Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'gomedia';
    src: url('../../dist/fonts/gomedia/gomedia.eot');
    src: url('../../dist/fonts/gomedia/gomedia.eot?#iefix') format('embedded-opentype'),
         url('../../dist/fonts/gomedia/gomedia.woff') format('woff'),
         url('../../dist/fonts/gomedia/gomedia.ttf') format('truetype'),
         url('../../dist/fonts/gomedia/gomedia.svg#gomedia') format('svg');
    font-weight: normal;
    font-style: normal;
}