.video-block {
  height: calc(100vh - 90px);
  @include gradient-overlay(rgba(255,255,255,0.34),rgba(255,255,255,0));
  &:after {
    content:'';
    padding-top: 100vh;
  }

  @include tablet-landscape-mobile {
    height: calc(100vh - 50px);
  }
  position: relative;
  overflow: hidden;
}
.video-bg, .video, .canvas {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  @include absolute-center();
  background-size: cover;
}

.video-info-box {
  @include absolute-center();
  width:510px;
  min-height:270px;
  padding:50px 45px;
  text-align:center;
  @include linear-gradient(rgba(255,255,255,0), rgba(0,0,0,0.25));

  @include mobile {
    width: calc(100% - 30px);
    height:auto;
    padding:30px 20px;
  }

  .video-title {
    color:$white;
    font-size:$font-xl;
    margin-top:30px;
    margin-bottom: 20px;

  }

  .video-text {
    color: $white;
    @include text-line(5);
  }
}

.flexbox-slider {
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;
  width: 100%;
  visibility: hidden;

  @include tablet-landscape-mobile {
    display:block;
  }
  @include tablet-landscape {
    display:block;
    position: relative;
    height: 800px;
  }

  .flexbox-slide {
    @include gradient-overlay(rgba(0,0,0,0.7),rgba(255,255,255,0));
    @include transition-mix($duration: .3s);
    width: 25%;
    height: 100%;
    position: relative;
    overflow: hidden;
    visibility: visible;
    @include bg-center(400px);

    @include mobile {
      width:100%;
    }
    @include tablet-landscape {
      width:50%;
      float: left;

      position: absolute;
      &:nth-child(1) {
        left:0;
        top:0;
      }
      &:nth-child(2) {
        right:0;
        top:0;
      }
      &:nth-child(3) {
        left:0;
        top:400px;
      }
      &:nth-child(4) {
        right:0;
        top:400px;
      }
    }

    .slider-title {
      position:absolute;
      top:30px;
      left:30px;
      width:100px;
      height:70px;

      @include mobile {
        &:after {
          content:'';
          height:3px;
          width:33px;
          background-color:$yellow;
          position:absolute;
          left:0;
          top:100%;
          margin-top:10px;
          @include transition-mix($delay: .5s);

        }
      }

      h1 {
        color:$white;
        margin-top:0;
        margin-bottom:0;
        font-size:$font-lg;
        line-height:24px;
      }

      img {
        width:100%;
        height:auto;
      }
    }

    .text-block {
      position:absolute;
      top:110px;
      left:30px;
      max-width:270px;
      z-index: 4;
      visibility: hidden;
      padding-top:20px;

      @include tablet-landscape-mobile {
        visibility:visible;
      }

      .slide-info {
        color:$white;
        font-size:$font-sm;
        line-height:22px;
        @include text-line(6);
        margin-bottom:30px;


      }
    }
  }

  @include desktop-menu {
    .flexbox-slide:hover {
      flex-shrink: 0;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      width: 50%;

      .slider-title {
        &:after {
          content:'';
          height:3px;
          width:33px;
          background-color:$yellow;
          position:absolute;
          left:0;
          top:100%;
          margin-top:10px;
          @include transition-mix($delay: .5s);

        }
      }
      .text-block {
        @include transition-mix($delay: .5s);
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
