/* VARIABLES
========================================================================== */
/* MIXINS
========================================================================== */
/* PLACEHOLDERS
========================================================================== */
/* COMPONENTS
========================================================================== */
.block-404 {
  position: relative; }
  .block-404 .box-404 {
    width: 510px;
    height: 350px;
    position: relative;
    position: absolute;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    top: 50px; }
    .block-404 .box-404:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(255, 255, 255, 0);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0), white);
      background: -o-linear-gradient(top, rgba(255, 255, 255, 0), white);
      background: -ms-linear-gradient(top, rgba(255, 255, 255, 0), white);
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), white); }
    @media (max-width: 767px) {
      .block-404 .box-404 {
        width: calc(100% - 30px);
        height: auto;
        /* max 767px*/ } }
    .block-404 .box-404 .txt-404 {
      font-size: 21em;
      line-height: 273px;
      text-align: center;
      color: #232323; }
      @media (max-width: 767px) {
        .block-404 .box-404 .txt-404 {
          font-size: 15em;
          /* max 767px*/ } }
    .block-404 .box-404 .box-404-info {
      position: absolute;
      width: 100%;
      bottom: 20px;
      text-align: center; }
      .block-404 .box-404 .box-404-info .not-found-txt {
        font-size: 3.85em;
        color: #232323;
        text-transform: capitalize;
        margin-bottom: 30px; }
        @media (max-width: 767px) {
          .block-404 .box-404 .box-404-info .not-found-txt {
            font-size: 3em;
            /* max 767px*/ } }
      .block-404 .box-404 .box-404-info .go-back-txt {
        font-size: 1em;
        line-height: 13px;
        margin-bottom: 30px;
        text-transform: capitalize; }

.contact-container {
  background-color: #fff;
  position: relative;
  z-index: 1; }
  .contact-container:not(:last-child) {
    margin-bottom: 20px; }

.contact-form-block .gform_confirmation_wrapper .gform_confirmation_message {
  text-align: center;
  margin-bottom: 20px; }

@media (min-width: 768px) {
  .contact-form-block .gform_wrapper {
    padding: 0 35px;
    /* min 768px */ } }

.contact-form-block .gform_wrapper .validation_error {
  text-align: center;
  margin-bottom: 20px; }

.contact-form-block .gform_wrapper .gfield_description.validation_message {
  margin-left: 2px;
  margin-top: 5px; }

.contact-form-block .gform_wrapper .gform_body ul {
  list-style: none;
  padding: 0;
  margin: 0 -10px; }
  .contact-form-block .gform_wrapper .gform_body ul li {
    display: block;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px; }
    .contact-form-block .gform_wrapper .gform_body ul li label {
      display: none;
      margin: 0; }
    .contact-form-block .gform_wrapper .gform_body ul li input {
      height: 34px; }
    .contact-form-block .gform_wrapper .gform_body ul li input, .contact-form-block .gform_wrapper .gform_body ul li textarea {
      width: 100%;
      border: 1px solid #232323;
      font-size: 0.92em;
      color: #232323;
      padding: 6px 12px;
      background-color: #fff; }
      .contact-form-block .gform_wrapper .gform_body ul li input:focus, .contact-form-block .gform_wrapper .gform_body ul li textarea:focus {
        outline: none;
        border-color: #eaaa2a; }
        .contact-form-block .gform_wrapper .gform_body ul li input:focus::placeholder, .contact-form-block .gform_wrapper .gform_body ul li textarea:focus::placeholder {
          color: #eaaa2a; }
        .contact-form-block .gform_wrapper .gform_body ul li input:focus::-webkit-input-placeholder, .contact-form-block .gform_wrapper .gform_body ul li textarea:focus::-webkit-input-placeholder {
          color: #eaaa2a; }
        .contact-form-block .gform_wrapper .gform_body ul li input:focus::-moz-placeholder, .contact-form-block .gform_wrapper .gform_body ul li textarea:focus::-moz-placeholder {
          color: #eaaa2a; }
        .contact-form-block .gform_wrapper .gform_body ul li input:focus::-moz-placeholder, .contact-form-block .gform_wrapper .gform_body ul li textarea:focus::-moz-placeholder {
          color: #eaaa2a; }
        .contact-form-block .gform_wrapper .gform_body ul li input:focus::-ms-input-placeholder, .contact-form-block .gform_wrapper .gform_body ul li textarea:focus::-ms-input-placeholder {
          color: #eaaa2a; }
      .contact-form-block .gform_wrapper .gform_body ul li input::placeholder, .contact-form-block .gform_wrapper .gform_body ul li textarea::placeholder {
        color: #232323;
        text-transform: uppercase; }
      .contact-form-block .gform_wrapper .gform_body ul li input::-webkit-input-placeholder, .contact-form-block .gform_wrapper .gform_body ul li textarea::-webkit-input-placeholder {
        color: #232323;
        text-transform: uppercase; }
      .contact-form-block .gform_wrapper .gform_body ul li input::-moz-placeholder, .contact-form-block .gform_wrapper .gform_body ul li textarea::-moz-placeholder {
        color: #232323;
        text-transform: uppercase; }
      .contact-form-block .gform_wrapper .gform_body ul li input::-moz-placeholder, .contact-form-block .gform_wrapper .gform_body ul li textarea::-moz-placeholder {
        color: #232323;
        text-transform: uppercase; }
      .contact-form-block .gform_wrapper .gform_body ul li input::-ms-input-placeholder, .contact-form-block .gform_wrapper .gform_body ul li textarea::-ms-input-placeholder {
        color: #232323;
        text-transform: uppercase; }
  @media (min-width: 768px) {
    .contact-form-block .gform_wrapper .gform_body ul li:nth-child(1), .contact-form-block .gform_wrapper .gform_body ul li:nth-child(2),
    .contact-form-block .gform_wrapper .gform_body ul li:nth-child(3), .contact-form-block .gform_wrapper .gform_body ul li:nth-child(4) {
      float: left;
      width: 50%;
      /* min 768px */ } }

.contact-form-block .gform_wrapper .gform_footer input[type="submit"] {
  float: right;
  margin-top: 30px;
  margin-bottom: 50px;
  border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -webkit-border-radius: 0;
  color: #fff;
  font-size: 1.08em;
  line-height: 14px;
  text-transform: uppercase;
  padding: 12px 15px;
  min-width: 100px;
  border: 1px solid #232323;
  background-color: #232323; }
  .contact-form-block .gform_wrapper .gform_footer input[type="submit"]:hover, .contact-form-block .gform_wrapper .gform_footer input[type="submit"]:focus {
    background-color: #eaaa2a;
    border-color: #eaaa2a;
    outline: none; }

.contact-info-block {
  padding: 20px;
  text-align: center; }
  .contact-info-block .contact-info {
    font-size: 1em;
    line-height: 16px; }
    .contact-info-block .contact-info span {
      color: #232323;
      margin-right: 3px; }
    .contact-info-block .contact-info a {
      color: #474747; }
      .contact-info-block .contact-info a:hover, .contact-info-block .contact-info a:focus, .contact-info-block .contact-info a:active {
        color: #eaaa2a; }

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.animated-background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
  background: #f6f7f8;
  background: #eeeeee;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background: -moz-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background: -o-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background: -ms-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative; }

.grid-load {
  background: #fff;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  height: 190px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .grid-load {
      height: 245px;
      /* min 768px -max 991px */ } }
  @media (min-width: 480px) and (max-width: 767px) {
    .grid-load {
      height: 300px;
      /*min 480px- max 767px */ } }
  @media (max-width: 479px) {
    .grid-load {
      height: 200px;
      /* max 479px */ } }
  .grid-load .img-bg {
    width: 100%;
    height: 190px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .grid-load .img-bg {
        height: 245px;
        /* min 768px -max 991px */ } }
    @media (min-width: 480px) and (max-width: 767px) {
      .grid-load .img-bg {
        height: 300px;
        /*min 480px- max 767px */ } }
    @media (max-width: 479px) {
      .grid-load .img-bg {
        height: 200px;
        /* max 479px */ } }
  .grid-load .title-bg {
    left: 15px;
    width: calc( 100% - 60px);
    height: 10px;
    top: 20px; }
  .grid-load .author-bg {
    left: 15px;
    width: 30%;
    height: 7px;
    top: 40px; }
  .grid-load .description1-bg {
    width: calc( 100% - 67px);
    left: 15px;
    height: 8px;
    top: 67px; }
  .grid-load .description2-bg {
    width: calc( 100% - 30px);
    left: 15px;
    height: 8px;
    top: 77px; }
  .grid-load .description3-bg {
    width: calc( 100% - 40px);
    left: 15px;
    height: 8px;
    top: 87px; }
  .grid-load .description4-bg {
    width: calc( 100% - 60px);
    left: 15px;
    height: 8px;
    top: 97px; }
  .grid-load .description5-bg {
    width: calc( 100% - 35px);
    left: 15px;
    height: 8px;
    top: 107px; }

@media (min-width: 992px) {
  .col-load-bordered {
    margin-bottom: 30px;
    /* min 992px */ }
    .col-load-bordered:nth-child(3n+1):after {
      content: "";
      height: 1px;
      background-color: rgba(0, 0, 0, 0.15);
      width: 860px;
      position: absolute;
      bottom: 0;
      left: 10px; }
    .col-load-bordered .grid-load {
      margin-bottom: 30px; } }

.col-load-full {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.grid-load-with-text {
  height: 355px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .grid-load-with-text {
      height: 425px;
      /* min 768px -max 991px */ } }
  @media (min-width: 480px) and (max-width: 767px) {
    .grid-load-with-text {
      height: 490px;
      /*min 480px- max 767px */ } }
  @media (max-width: 479px) {
    .grid-load-with-text {
      height: 400px;
      /* max 479px */ } }

.grid-load-with-title {
  height: 250px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .grid-load-with-title {
      height: 320px;
      /* min 768px -max 991px */ } }
  @media (min-width: 480px) and (max-width: 767px) {
    .grid-load-with-title {
      height: 380px;
      /*min 480px- max 767px */ } }
  @media (max-width: 479px) {
    .grid-load-with-title {
      height: 275px;
      /* max 479px */ } }

.grid-load-with-text .img-bg, .grid-load-with-title .img-bg {
  width: 100%;
  height: 175px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .grid-load-with-text .img-bg, .grid-load-with-title .img-bg {
      height: 245px;
      /* min 768px -max 991px */ } }
  @media (min-width: 480px) and (max-width: 767px) {
    .grid-load-with-text .img-bg, .grid-load-with-title .img-bg {
      height: 300px;
      /*min 480px- max 767px */ } }
  @media (max-width: 479px) {
    .grid-load-with-text .img-bg, .grid-load-with-title .img-bg {
      height: 200px;
      /* max 479px */ } }

.grid-load-full-width {
  margin-bottom: 0;
  height: 320px; }
  .grid-load-full-width .img-bg {
    height: 320px;
    box-shadow: inset 0 -5px 5px -5px #dddddd;
    -moz-box-shadow: inset 0 -5px 5px -5px #dddddd;
    -webkit-box-shadow: inset 0 -5px 5px -5px #dddddd; }

.col-load-search .grid-load {
  box-shadow: none;
  margin-bottom: 0; }
  @media (min-width: 768px) {
    .col-load-search .grid-load {
      height: 130px;
      /* min 768px */ } }
  @media (min-width: 768px) {
    .col-load-search .grid-load .img-bg {
      width: 200px;
      height: 130px;
      float: left;
      /* min 768px */ } }
  @media (min-width: 768px) {
    .col-load-search .grid-load .info-bg {
      margin-left: 200px;
      /* min 768px */ } }
  @media (min-width: 768px) {
    .col-load-search .grid-load .info-bg {
      /* min 768px */ }
      .col-load-search .grid-load .info-bg .title-bg {
        top: 5px;
        left: 20px; }
      .col-load-search .grid-load .info-bg .author-bg {
        top: 15px;
        left: 20px; }
      .col-load-search .grid-load .info-bg .description1-bg {
        top: 30px;
        left: 20px; }
      .col-load-search .grid-load .info-bg .description2-bg {
        top: 40px;
        left: 20px; }
      .col-load-search .grid-load .info-bg .description3-bg {
        top: 50px;
        left: 20px; }
      .col-load-search .grid-load .info-bg .description4-bg {
        top: 60px;
        left: 20px; }
      .col-load-search .grid-load .info-bg .description5-bg {
        top: 70px;
        left: 20px; } }

.col-load-gallery .grid-load {
  margin-bottom: 0;
  height: 300px; }
  @media (min-width: 480px) and (max-width: 767px) {
    .col-load-gallery .grid-load {
      height: 400px;
      /*min 480px- max 767px */ } }
  @media (max-width: 479px) {
    .col-load-gallery .grid-load {
      height: 500px;
      /* max 479px */ } }
  .col-load-gallery .grid-load .img-bg {
    height: 300px; }
    @media (min-width: 480px) and (max-width: 767px) {
      .col-load-gallery .grid-load .img-bg {
        height: 400px;
        /*min 480px- max 767px */ } }
    @media (max-width: 479px) {
      .col-load-gallery .grid-load .img-bg {
        height: 500px;
        /* max 479px */ } }

.dropdown-bordered a.lang {
  border: 1px solid #232323;
  text-transform: uppercase;
  font-size: 0.92em;
  line-height: 12px;
  height: 30px;
  padding: 8px 30px 8px 10px;
  display: inline-block; }
  .dropdown-bordered a.lang:hover, .dropdown-bordered a.lang:focus {
    text-decoration: none; }

.dropdown-bordered a.dropdown-toggle {
  display: block;
  cursor: pointer;
  color: #232323;
  display: block;
  color: #232323;
  border: 1px solid #232323;
  text-transform: uppercase;
  font-size: 0.92em;
  line-height: 12px;
  height: 30px;
  padding: 8px 30px 8px 10px; }
  .dropdown-bordered a.dropdown-toggle:after {
    content: '';
    background-image: url(../../dist/images/icons/arrow.svg);
    display: block;
    width: 10px;
    height: 5px;
    position: absolute;
    right: 10px;
    top: 12px; }
  .dropdown-bordered a.dropdown-toggle:hover {
    text-decoration: none; }
  .dropdown-bordered a.dropdown-toggle[aria-expanded="true"] {
    background-color: #232323;
    color: #fff; }
    .dropdown-bordered a.dropdown-toggle[aria-expanded="true"]:after {
      background-image: url(../../dist/images/icons/arrow-white.svg); }

.dropdown-bordered .dropdown-menu {
  min-width: auto;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 1px solid #232323;
  border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -webkit-border-radius: 0;
  box-shadow: none; }
  .dropdown-bordered .dropdown-menu > li {
    height: 30px;
    padding: 9px 0; }
    .dropdown-bordered .dropdown-menu > li:not(:last-child) {
      border-bottom: 1px solid #232323; }
    .dropdown-bordered .dropdown-menu > li > a {
      text-transform: uppercase;
      color: #232323;
      font-size: 0.92em;
      line-height: 12px;
      padding: 0;
      text-align: center; }
      .dropdown-bordered .dropdown-menu > li > a:hover, .dropdown-bordered .dropdown-menu > li > a:focus {
        background-color: transparent;
        color: #eaaa2a; }

.filters {
  padding: 30px 35px; }
  @media (max-width: 767px) {
    .filters {
      padding: 30px 0;
      /* max 767px*/ } }
  .filters .filter {
    float: left;
    margin-right: 20px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .filters .filter {
        width: 33%;
        padding: 0 10px;
        margin: 0;
        /* min 768px -max 991px */ } }
    @media (min-width: 480px) and (max-width: 767px) {
      .filters .filter {
        width: 33%;
        padding: 0 5px;
        margin: 0;
        /*min 480px- max 767px */ } }
    @media (min-width: 480px) and (max-width: 599px) {
      .filters .filter {
        /* min 480px -max 599px */ }
        .filters .filter:nth-child(1), .filters .filter:nth-child(2) {
          width: calc(50% - 5px);
          float: left;
          margin: 0; }
        .filters .filter:nth-child(2) {
          width: calc(50% - 5px);
          float: right; } }
    @media (max-width: 479px) {
      .filters .filter {
        /* max 479px */ }
        .filters .filter:nth-child(1), .filters .filter:nth-child(2) {
          width: 100%; }
        .filters .filter:nth-child(2) {
          margin-top: 10px; } }
    @media (min-width: 992px) {
      .filters .filter.select-filter {
        width: calc(33% - 20px);
        /* min 992px */ } }
    .filters .filter.select-filter .dropdown .dropdown-toggle {
      border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -webkit-border-radius: 0;
      border: 1px solid #232323;
      padding: 0 24px 0 8px;
      height: 28px; }
      .filters .filter.select-filter .dropdown .dropdown-toggle button.clear {
        display: none; }
      .filters .filter.select-filter .dropdown .dropdown-toggle input.form-control {
        width: auto !important;
        height: 28px;
        padding: 0;
        position: relative; }
    .filters .filter.select-filter .dropdown .selected-tag {
      margin: 0;
      padding: 0;
      color: #232323;
      text-transform: uppercase;
      font-size: 0.92em;
      line-height: 28px;
      border: none;
      border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -webkit-border-radius: 0;
      position: absolute; }
    .filters .filter.select-filter .dropdown .open-indicator {
      height: 5px;
      bottom: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      right: 8px; }
      .filters .filter.select-filter .dropdown .open-indicator:before {
        border: none;
        background-image: url(../../dist/images/icons/arrow.svg);
        width: 10px;
        height: 5px;
        transform: rotate(0deg); }
    .filters .filter.select-filter .dropdown .dropdown-menu {
      background-color: #fff;
      max-height: 121px !important;
      overflow-y: scroll;
      border: 1px solid #232323;
      border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -webkit-border-radius: 0;
      padding: 0;
      min-width: auto; }
      .filters .filter.select-filter .dropdown .dropdown-menu::-webkit-scrollbar-track {
        border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -webkit-border-radius: 10px;
        background-color: #fff; }
      .filters .filter.select-filter .dropdown .dropdown-menu::-webkit-scrollbar {
        width: 4px;
        background-color: #fff; }
      .filters .filter.select-filter .dropdown .dropdown-menu::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -webkit-border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.15); }
      .filters .filter.select-filter .dropdown .dropdown-menu > li {
        height: 30px; }
        .filters .filter.select-filter .dropdown .dropdown-menu > li:not(:last-child) {
          border-bottom: 1px solid #232323; }
        .filters .filter.select-filter .dropdown .dropdown-menu > li > a {
          text-transform: uppercase;
          color: #232323;
          font-size: 0.92em;
          line-height: 30px;
          padding: 0;
          text-align: center;
          font-weight: normal !important; }
        .filters .filter.select-filter .dropdown .dropdown-menu > li.highlight > a {
          background: transparent; }
        .filters .filter.select-filter .dropdown .dropdown-menu > li:hover {
          background: transparent; }
          .filters .filter.select-filter .dropdown .dropdown-menu > li:hover > a {
            color: #eaaa2a; }
    .filters .filter.select-filter .dropdown.open .open-indicator:before {
      transform: rotate(180deg); }
    .filters .filter.select-filter .dropdown.open .selected-tag {
      opacity: 1;
      position: absolute; }
    .filters .filter.unsearchable-filter .dropdown .dropdown-toggle input.form-control {
      cursor: pointer;
      color: transparent !important;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none; }
      .filters .filter.unsearchable-filter .dropdown .dropdown-toggle input.form-control:focus {
        outline: none; }
    @media (min-width: 480px) and (max-width: 599px) {
      .filters .filter.author-filter {
        width: 100%;
        margin-top: 10px;
        /* min 480px -max 599px */ } }
    @media (max-width: 479px) {
      .filters .filter.author-filter {
        width: 100%;
        margin-top: 10px;
        /* max 479px */ } }
    .filters .filter.author-filter .dropdown .dropdown-toggle input.form-control {
      color: inherit !important; }
    .filters .filter.author-filter .dropdown .dropdown-menu > li {
      border: none !important;
      padding: 5px 10px;
      height: auto; }
      .filters .filter.author-filter .dropdown .dropdown-menu > li > a {
        line-height: 14px;
        text-transform: capitalize;
        text-align: left;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden; }
    .filters .filter.author-filter .dropdown.open .selected-tag {
      opacity: 0.5; }

@font-face {
  font-family: 'Antonio-Bold';
  src: url("../../dist/fonts/Antonio-Bold/Antonio-Bold.eot");
  src: url("../../dist/fonts/Antonio-Bold/Antonio-Bold.woff2") format("woff2"), url("../../dist/fonts/Antonio-Bold/Antonio-Bold.woff") format("woff"), url("../../dist/fonts/Antonio-Bold/Antonio-Bold.ttf") format("truetype"), url("../../dist/fonts/Antonio-Bold/Antonio-Bold.svg#Antonio-Bold") format("svg"), url("../../dist/fonts/Antonio-Bold/Antonio-Bold.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Assistant-Bold';
  src: url("../../dist/fonts/Assistant-Bold/Assistant-Bold.eot");
  src: url("../../dist/fonts/Assistant-Bold/Assistant-Bold.woff2") format("woff2"), url("../../dist/fonts/Assistant-Bold/Assistant-Bold.woff") format("woff"), url("../../dist/fonts/Assistant-Bold/Assistant-Bold.ttf") format("truetype"), url("../../dist/fonts/Assistant-Bold/Assistant-Bold.svg#Assistant-Bold") format("svg"), url("../../dist/fonts/Assistant-Bold/Assistant-Bold.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Assistant-Regular';
  src: url("../../dist/fonts/Assistant-Regular/Assistant-Regular.eot");
  src: url("../../dist/fonts/Assistant-Regular/Assistant-Regular.woff2") format("woff2"), url("../../dist/fonts/Assistant-Regular/Assistant-Regular.woff") format("woff"), url("../../dist/fonts/Assistant-Regular/Assistant-Regular.ttf") format("truetype"), url("../../dist/fonts/Assistant-Regular/Assistant-Regular.svg#Assistant-Regular") format("svg"), url("../../dist/fonts/Assistant-Regular/Assistant-Regular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'BPG ExtraSquare Mtavruli';
  src: url("../../dist/fonts/BPGExtraSquareMtavruli/BPGExtraSquareMtavruli.eot");
  src: url("../../dist/fonts/BPGExtraSquareMtavruli/BPGExtraSquareMtavruli.eot?#iefix") format("embedded-opentype"), url("../../dist/fonts/BPGExtraSquareMtavruli/BPGExtraSquareMtavruli.woff2") format("woff2"), url("../../dist/fonts/BPGExtraSquareMtavruli/BPGExtraSquareMtavruli.woff") format("woff"), url("../../dist/fonts/BPGExtraSquareMtavruli/BPGExtraSquareMtavruli.ttf") format("truetype"), url("../../dist/fonts/BPGExtraSquareMtavruli/BPGExtraSquareMtavruli.svg#BPGExtraSquareMtavruli") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'BPG Nateli';
  src: url("../../dist/fonts/BPGNateli/BPGNateli.eot");
  src: url("../../dist/fonts/BPGNateli/BPGNateli.eot?#iefix") format("embedded-opentype"), url("../../dist/fonts/BPGNateli/BPGNateli.woff2") format("woff2"), url("../../dist/fonts/BPGNateli/BPGNateli.woff") format("woff"), url("../../dist/fonts/BPGNateli/BPGNateli.ttf") format("truetype"), url("../../dist/fonts/BPGNateli/BPGNateli.svg#BPGNateli") format("svg");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Oswald-SemiBold';
  src: url("../../dist/fonts/Oswald-SemiBold/Oswald-SemiBold.eot");
  src: url("../../dist/fonts/Oswald-SemiBold/Oswald-SemiBold.eot?#iefix") format("embedded-opentype"), url("../../dist/fonts/Oswald-SemiBold/Oswald-SemiBold.woff2") format("woff2"), url("../../dist/fonts/Oswald-SemiBold/Oswald-SemiBold.woff") format("woff"), url("../../dist/fonts/Oswald-SemiBold/Oswald-SemiBold.ttf") format("truetype"), url("../../dist/fonts/Oswald-SemiBold/Oswald-SemiBold.svg#Oswald-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Bold';
  src: url("../../dist/fonts/Roboto-Bold/Roboto-Bold.eot");
  src: url("../../dist/fonts/Roboto-Bold/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../../dist/fonts/Roboto-Bold/Roboto-Bold.woff2") format("woff2"), url("../../dist/fonts/Roboto-Bold/Roboto-Bold.woff") format("woff"), url("../../dist/fonts/Roboto-Bold/Roboto-Bold.ttf") format("truetype"), url("../../dist/fonts/Roboto-Bold/Roboto-Bold.svg#Roboto-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Roboto-Light';
  src: url("../../dist/fonts/Roboto-Light/Roboto-Light.eot");
  src: url("../../dist/fonts/Roboto-Light/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../../dist/fonts/Roboto-Light/Roboto-Light.woff2") format("woff2"), url("../../dist/fonts/Roboto-Light/Roboto-Light.woff") format("woff"), url("../../dist/fonts/Roboto-Light/Roboto-Light.ttf") format("truetype"), url("../../dist/fonts/Roboto-Light/Roboto-Light.svg#Roboto-Light") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'gomedia';
  src: url("../../dist/fonts/gomedia/gomedia.eot");
  src: url("../../dist/fonts/gomedia/gomedia.eot?#iefix") format("embedded-opentype"), url("../../dist/fonts/gomedia/gomedia.woff") format("woff"), url("../../dist/fonts/gomedia/gomedia.ttf") format("truetype"), url("../../dist/fonts/gomedia/gomedia.svg#gomedia") format("svg");
  font-weight: normal;
  font-style: normal; }

footer {
  background-color: #232323;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 48px; }
  footer .copyright {
    color: #fff;
    line-height: 48px; }

.content {
  padding-bottom: 48px; }

.images-grid .img-grid-row > .row > .img-grid-col {
  margin-bottom: 20px; }
  .images-grid .img-grid-row > .row > .img-grid-col .img-grid-item {
    width: 100%;
    cursor: pointer; }
    .images-grid .img-grid-row > .row > .img-grid-col .img-grid-item .img-holder {
      width: 100%;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      height: 160px; }
  @media (max-width: 767px) {
    .images-grid .img-grid-row > .row > .img-grid-col {
      margin-bottom: 10px;
      /* max 767px*/ } }
  @media (min-width: 480px) and (max-width: 767px) {
    .images-grid .img-grid-row > .row > .img-grid-col {
      width: 50%;
      float: left;
      padding-left: 5px;
      padding-right: 5px;
      /*min 480px- max 767px */ } }
  @media (max-width: 479px) {
    .images-grid .img-grid-row > .row > .img-grid-col {
      padding-left: 0;
      padding-right: 0;
      /* max 479px */ } }
  @media (max-width: 767px) {
    .images-grid .img-grid-row > .row > .img-grid-col > .row {
      margin-left: -5px;
      margin-right: -5px;
      /* max 767px*/ } }
  @media (max-width: 767px) {
    .images-grid .img-grid-row > .row > .img-grid-col > .row .img-grid-col {
      width: 50%;
      float: left;
      padding-left: 5px;
      padding-right: 5px;
      /* max 767px*/ } }

@media (min-width: 480px) and (max-width: 767px) {
  .images-grid .img-grid-row .row {
    /*min 480px- max 767px */ }
    .images-grid .img-grid-row .row:after, .images-grid .img-grid-row .row:before {
      display: inline; } }

.trainers-grid {
  margin: 30px 0; }
  .trainers-grid .img-grid-row {
    margin-bottom: 0; }
    .trainers-grid .img-grid-row .row {
      margin-left: -5px;
      margin-right: -5px; }
      @media (max-width: 767px) {
        .trainers-grid .img-grid-row .row:before, .trainers-grid .img-grid-row .row:after {
          display: inline;
          /* max 767px*/ } }
    @media (max-width: 767px) {
      .trainers-grid .img-grid-row .img-grid-col {
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 10px;
        /* max 767px*/ } }
    @media (min-width: 480px) and (max-width: 767px) {
      .trainers-grid .img-grid-row .img-grid-col {
        width: 50%;
        float: left;
        /*min 480px- max 767px */ } }
    .trainers-grid .img-grid-row .img-grid-col .img-grid-item {
      width: 100%;
      position: relative;
      transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      cursor: pointer; }
      .trainers-grid .img-grid-row .img-grid-col .img-grid-item .img-holder {
        position: relative;
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 300px; }
        .trainers-grid .img-grid-row .img-grid-col .img-grid-item .img-holder:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.34);
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0.34), rgba(255, 255, 255, 0));
          background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.34), rgba(255, 255, 255, 0));
          background: -o-linear-gradient(top, rgba(0, 0, 0, 0.34), rgba(255, 255, 255, 0));
          background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.34), rgba(255, 255, 255, 0));
          background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.34), rgba(255, 255, 255, 0)); }
        @media (min-width: 480px) and (max-width: 767px) {
          .trainers-grid .img-grid-row .img-grid-col .img-grid-item .img-holder {
            width: 100%;
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            height: 400px;
            /*min 480px- max 767px */ } }
        @media (max-width: 479px) {
          .trainers-grid .img-grid-row .img-grid-col .img-grid-item .img-holder {
            width: 100%;
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            height: 500px;
            /* max 479px */ } }
      .trainers-grid .img-grid-row .img-grid-col .img-grid-item .trainer-name {
        text-transform: uppercase;
        color: #fff;
        font-size: 1.3em;
        line-height: 25px;
        position: absolute;
        top: 20px;
        left: 20px;
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease; }
        .trainers-grid .img-grid-row .img-grid-col .img-grid-item .trainer-name:after {
          transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          content: ''; }
      .trainers-grid .img-grid-row .img-grid-col .img-grid-item:hover {
        box-shadow: 2px -1px 15px 4px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 2px -1px 15px 4px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 2px -1px 15px 4px rgba(0, 0, 0, 0.15); }
        .trainers-grid .img-grid-row .img-grid-col .img-grid-item:hover .trainer-name:after {
          content: '';
          position: absolute;
          width: 33px;
          height: 3px;
          background-color: #eaaa2a;
          margin-top: 15px;
          left: 0;
          top: 100%; }
    .trainers-grid .img-grid-row .img-grid-col.img-grid-col-divided .img-grid-item:first-child {
      margin-bottom: 20px; }
      @media (max-width: 767px) {
        .trainers-grid .img-grid-row .img-grid-col.img-grid-col-divided .img-grid-item:first-child {
          margin-bottom: 10px;
          /* max 767px*/ } }
    .trainers-grid .img-grid-row .img-grid-col.img-grid-col-divided .img-holder {
      width: 100%;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      height: 140px; }
      @media (min-width: 480px) and (max-width: 767px) {
        .trainers-grid .img-grid-row .img-grid-col.img-grid-col-divided .img-holder {
          width: 100%;
          background-size: cover;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          height: 195px;
          /*min 480px- max 767px */ } }
      @media (max-width: 479px) {
        .trainers-grid .img-grid-row .img-grid-col.img-grid-col-divided .img-holder {
          width: 100%;
          background-size: cover;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          height: 500px;
          /* max 479px */ } }
    .trainers-grid .img-grid-row .img-grid-col.img-grid-col-4 .img-holder {
      width: 100%;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      height: 340px; }

.gallery-block {
  background-color: rgba(0, 0, 0, 0.54);
  position: fixed;
  top: 90px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  display: none; }
  @media (max-width: 991px) {
    .gallery-block {
      top: 60px;
      /* max 991px */ } }
  .gallery-block .close-gallery {
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50px;
    right: 50px;
    background-image: url(../../dist/images/icons/close-white.svg); }
    @media (max-width: 991px) {
      .gallery-block .close-gallery {
        top: 20px;
        right: 20px;
        /* max 991px */ } }
  .gallery-block .gallery-slider {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease; }
    .gallery-block .gallery-slider .owl-stage {
      display: flex;
      align-items: center;
      display: -webkit-flex;
      -webkit-align-items: center; }
      .gallery-block .gallery-slider .owl-stage .owl-item {
        visibility: hidden; }
        .gallery-block .gallery-slider .owl-stage .owl-item.active {
          visibility: visible; }
    @media (min-width: 992px) {
      .gallery-block .gallery-slider {
        width: 870px;
        /* min 992px */ } }
    @media (min-width: 768px) and (max-width: 991px) {
      .gallery-block .gallery-slider {
        width: 600px;
        /* min 768px -max 991px */ } }
    @media (max-width: 767px) {
      .gallery-block .gallery-slider {
        width: 100%;
        /* max 767px*/ } }
    @media (min-width: 992px) {
      .gallery-block .gallery-slider .slider-item {
        max-width: 870px;
        max-height: 580px;
        /* min 992px */ } }
    @media (min-width: 1280px) and (max-width: 1366px) {
      .gallery-block .gallery-slider .slider-item {
        max-height: 450px;
        /*min 1280px - max 1366 */ } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .gallery-block .gallery-slider .slider-item {
        max-height: 450px;
        /* min 992px -max 1279px */ } }
    @media (min-width: 768px) and (max-width: 991px) {
      .gallery-block .gallery-slider .slider-item {
        max-width: 600px;
        /* min 768px -max 991px */ } }
    @media (max-width: 767px) {
      .gallery-block .gallery-slider .slider-item {
        max-width: 100%;
        /* max 767px*/ } }
    .gallery-block .gallery-slider .slider-item.landscape img {
      width: 100%;
      height: auto;
      margin: 0 auto; }
    @media (max-width: 767px) {
      .gallery-block .gallery-slider .slider-item.landscape {
        width: 100%;
        /* max 767px*/ } }
    .gallery-block .gallery-slider .slider-item.portrait img {
      width: auto;
      height: 100%;
      margin: 0 auto; }
    @media (min-width: 992px) {
      .gallery-block .gallery-slider .slider-item.portrait {
        height: 580px;
        /* min 992px */ } }
    @media (min-width: 1280px) and (max-width: 1366px) {
      .gallery-block .gallery-slider .slider-item.portrait {
        height: 450px;
        /*min 1280px - max 1366 */ } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .gallery-block .gallery-slider .slider-item.portrait {
        height: 450px;
        /* min 992px -max 1279px */ } }
    @media (min-width: 768px) and (max-width: 991px) {
      .gallery-block .gallery-slider .slider-item.portrait {
        height: 450px;
        /* min 768px -max 991px */ } }
    @media (max-width: 767px) {
      .gallery-block .gallery-slider .slider-item.portrait {
        height: 450px;
        /* max 767px*/ } }
    @media (min-width: 321px) and (max-width: 479px) {
      .gallery-block .gallery-slider .slider-item.portrait {
        height: 350px;
        /* min 321px -max 479px */ } }
    @media (max-width: 320px) {
      .gallery-block .gallery-slider .slider-item.portrait {
        height: 300px;
        /* max 320px */ } }
    .gallery-block .gallery-slider .owl-nav .disabled {
      display: none; }
    @media (max-width: 767px) {
      .gallery-block .gallery-slider .owl-nav {
        display: none;
        /* max 767px*/ } }
    .gallery-block .gallery-slider .owl-nav .owl-next .slider-arrow, .gallery-block .gallery-slider .owl-nav .owl-prev .slider-arrow {
      width: 40px;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.05);
      background-size: 20px auto;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      border: none; }
      .gallery-block .gallery-slider .owl-nav .owl-next .slider-arrow:hover, .gallery-block .gallery-slider .owl-nav .owl-prev .slider-arrow:hover {
        background-color: rgba(0, 0, 0, 0.1);
        background-image: url(../../dist/images/icons/arrow-white.svg); }
    .gallery-block .gallery-slider .owl-nav .owl-next {
      margin-right: -60px; }
      .gallery-block .gallery-slider .owl-nav .owl-next .slider-arrow {
        background-image: url(../../dist/images/icons/arrow-white.svg);
        transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg); }
    .gallery-block .gallery-slider .owl-nav .owl-prev {
      margin-left: -60px; }
      .gallery-block .gallery-slider .owl-nav .owl-prev .slider-arrow {
        background-image: url(../../dist/images/icons/arrow-white.svg);
        transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        -webkit-transform: rotate(270deg); }
  .gallery-block .carousel-content {
    display: none; }

@media (max-width: 767px) {
  .trainers-gallery-block {
    display: none !important;
    /* max 767px*/ } }

/************************************* Mobile menu earlier ********************************************/
@media (min-width: 768px) and (max-width: 1024px) {
  .navbar-header {
    float: none; }
  .navbar-toggle {
    display: block; }
  .navbar-collapse.collapse {
    display: none !important; }
  .navbar-collapse.collapse.in {
    display: block !important; }
  .navbar-nav {
    float: none !important;
    margin: 0; }
  .navbar-nav > li {
    float: none; }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px; }
  /* min 768 - max 1024px */ }

header {
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 5; }
  @media (min-width: 1025px) {
    header {
      height: 90px;
      box-shadow: 0 7px 10px -7px rgba(0, 0, 0, 0.12);
      -moz-box-shadow: 0 7px 10px -7px rgba(0, 0, 0, 0.12);
      -webkit-box-shadow: 0 7px 10px -7px rgba(0, 0, 0, 0.12);
      /* min 1025px */ } }
  header .main-navbar {
    border: none;
    border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -webkit-border-radius: 0;
    margin-bottom: 0;
    padding: 0 20px; }
    @media (max-width: 1024px) {
      header .main-navbar {
        padding: 0;
        /* max 1024px */ } }
    @media (max-width: 1024px) {
      header .main-navbar.active-submenu {
        /* max 1024px */ }
        header .main-navbar.active-submenu .main-navbar-collapse {
          left: -45%;
          position: relative;
          top: 3px; }
          header .main-navbar.active-submenu .main-navbar-collapse:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(255, 255, 255, 0);
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
            background: -moz-linear-gradient(top, rgba(255, 255, 255, 0), white);
            background: -o-linear-gradient(top, rgba(255, 255, 255, 0), white);
            background: -ms-linear-gradient(top, rgba(255, 255, 255, 0), white);
            background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), white); }
          header .main-navbar.active-submenu .main-navbar-collapse:before, header .main-navbar.active-submenu .main-navbar-collapse:after {
            display: block; }
        header .main-navbar.active-submenu .search {
          z-index: -1; }
        header .main-navbar.active-submenu .submenu-block {
          height: calc(100vh - 60px);
          background-color: #fff;
          box-shadow: -10px 0 10px -10px rgba(0, 0, 0, 0.12);
          -moz-box-shadow: -10px 0 10px -10px rgba(0, 0, 0, 0.12);
          -webkit-box-shadow: -10px 0 10px -10px rgba(0, 0, 0, 0.12);
          position: absolute;
          top: 63px;
          width: 80%;
          left: 20%;
          z-index: 1; }
        header .main-navbar.active-submenu .submenu {
          background-color: transparent;
          display: block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          -o-transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          top: 80px;
          box-shadow: none;
          margin: 0;
          padding: 0; }
          header .main-navbar.active-submenu .submenu > li > a {
            padding: 25px 15px; } }
      @media (max-width: 1024px) and (max-width: 320px) {
        header .main-navbar.active-submenu .submenu > li > a {
          padding: 15px;
          /* max 320px */ } }
    header .main-navbar .menu-btn {
      border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -webkit-border-radius: 0;
      background-image: url(../../dist/images/icons/menu-btn.svg);
      background-repeat: no-repeat;
      width: 20px;
      height: 15px;
      padding: 0;
      margin: 20px 0 0 15px;
      float: left; }
      header .main-navbar .menu-btn[aria-expanded="true"] {
        background-image: url(../../dist/images/icons/menu-close.svg);
        width: 15px;
        height: 15px; }
    @media (max-width: 1024px) {
      header .main-navbar .main-navbar-header {
        box-shadow: 0 7px 10px -7px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0 7px 10px -7px rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: 0 7px 10px -7px rgba(0, 0, 0, 0.12);
        height: 60px;
        /* max 1024px */ } }
    header .main-navbar .main-navbar-collapse {
      padding-left: 0;
      padding-right: 0; }
      @media (max-width: 1024px) {
        header .main-navbar .main-navbar-collapse {
          position: relative;
          height: calc(100vh - 60px) !important;
          /* max 1024px */ } }
    header .main-navbar .main-menu-list {
      margin: 0; }
      @media (max-width: 1024px) {
        header .main-navbar .main-menu-list {
          padding-top: 110px;
          /* max 1024px */ } }
      @media (min-width: 1025px) {
        header .main-navbar .main-menu-list > li {
          padding: 0 9px;
          /* min 1025px */ }
          header .main-navbar .main-menu-list > li:first-child {
            padding-left: 0; } }
      header .main-navbar .main-menu-list > li > a {
        font-size: 0.92em;
        line-height: 90px;
        color: #232323;
        text-transform: uppercase;
        padding: 0;
        transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease; }
        @media (max-width: 1024px) {
          header .main-navbar .main-menu-list > li > a {
            line-height: 12px;
            /* max 1024px */ } }
        header .main-navbar .main-menu-list > li > a:hover, header .main-navbar .main-menu-list > li > a:focus {
          background-color: transparent; }
      header .main-navbar .main-menu-list > li.open {
        background-color: transparent; }
        header .main-navbar .main-menu-list > li.open > a, header .main-navbar .main-menu-list > li.open > a:hover, header .main-navbar .main-menu-list > li.open:focus {
          background-color: transparent;
          box-shadow: none; }
      @media (min-width: 1025px) {
        header .main-navbar .main-menu-list > li:hover > a, header .main-navbar .main-menu-list > li.active > a {
          color: #eaaa2a;
          box-shadow: inset 0 -3px 0 0 #eaaa2a;
          -moz-box-shadow: inset 0 -3px 0 0 #eaaa2a;
          -webkit-box-shadow: inset 0 -3px 0 0 #eaaa2a;
          /* min 1025px */ } }
      @media (min-width: 1025px) {
        header .main-navbar .main-menu-list > li.dropdown {
          /* min 1025px */ }
          header .main-navbar .main-menu-list > li.dropdown:hover > a.dropdown-toggle {
            box-shadow: none; }
          header .main-navbar .main-menu-list > li.dropdown:hover .dropdown-menu {
            display: block; } }
      header .main-navbar .main-menu-list > li.dropdown > a.dropdown-toggle {
        display: inline-block;
        position: relative; }
        @media (max-width: 1024px) {
          header .main-navbar .main-menu-list > li.dropdown > a.dropdown-toggle {
            /* max 1024px */ }
            header .main-navbar .main-menu-list > li.dropdown > a.dropdown-toggle:after {
              content: '';
              background-image: url(../../dist/images/icons/arrow.svg);
              background-repeat: no-repeat;
              background-position: 50% 50%;
              transform: rotate(270deg);
              -ms-transform: rotate(270deg);
              -webkit-transform: rotate(270deg);
              width: 10px;
              height: 12px;
              position: absolute;
              left: 100%;
              margin-left: 5px; } }
        @media (max-width: 1024px) {
          header .main-navbar .main-menu-list > li.dropdown > a.dropdown-toggle {
            /* max 1024px */ }
            header .main-navbar .main-menu-list > li.dropdown > a.dropdown-toggle[aria-expanded="true"] {
              color: #eaaa2a; }
              header .main-navbar .main-menu-list > li.dropdown > a.dropdown-toggle[aria-expanded="true"]:after {
                background-image: url(../../dist/images/icons/arrow-yellow.svg); } }
      @media (max-width: 1024px) {
        header .main-navbar .main-menu-list > li.dropdown > .submenu {
          display: none !important;
          /* max 1024px */ } }
      @media (max-width: 1024px) {
        header .main-navbar .main-menu-list {
          text-align: center;
          /* max 1024px */ }
          header .main-navbar .main-menu-list > li {
            margin-bottom: 40px; } }
    @media (max-width: 1024px) and (max-width: 320px) {
      header .main-navbar .main-menu-list > li {
        margin-bottom: 20px;
        /* max 320px */ } }
    header .main-navbar .submenu {
      background-color: #fff;
      border: none;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15), inset 0 7px 9px -7px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15), inset 0 7px 9px -7px rgba(0, 0, 0, 0.15);
      -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15), inset 0 7px 9px -7px rgba(0, 0, 0, 0.15);
      margin: 0;
      border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -webkit-border-radius: 0; }
      @media (min-width: 1025px) {
        header .main-navbar .submenu {
          display: none;
          padding: 5px 0;
          width: 130px;
          left: 50%;
          transform: translateX(-50%);
          border-bottom: 3px solid #eaaa2a;
          /* min 1025px */ } }
      header .main-navbar .submenu > li > a {
        font-size: 0.92em;
        padding: 10px 20px;
        text-align: center;
        color: #232323;
        text-transform: uppercase;
        white-space: normal; }
        header .main-navbar .submenu > li > a:hover, header .main-navbar .submenu > li > a:focus {
          background-color: transparent; }
        header .main-navbar .submenu > li > a:hover {
          color: #eaaa2a; }
    header .main-navbar .logo {
      position: absolute;
      top: 10px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      -webkit-transform: translateX(-50%); }
      header .main-navbar .logo img {
        height: 30px;
        width: auto; }
      @media (min-width: 1025px) {
        header .main-navbar .logo {
          width: 200px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          /* min 1025px */ }
          header .main-navbar .logo img {
            width: 100%;
            height: auto; } }
    header .main-navbar .main-navbar-right {
      padding-top: 30px;
      float: right; }
      @media (max-width: 1024px) {
        header .main-navbar .main-navbar-right {
          float: none;
          padding-top: 0;
          /* max 1024px */ } }
      header .main-navbar .main-navbar-right .languages-dropdown {
        float: left;
        margin-left: 20px;
        margin-right: 30px; }
        header .main-navbar .main-navbar-right .languages-dropdown a.dropdown-toggle {
          width: 55px; }
        header .main-navbar .main-navbar-right .languages-dropdown .languages-list {
          width: 55px; }
        @media (max-width: 1024px) {
          header .main-navbar .main-navbar-right .languages-dropdown {
            display: none;
            /* max 1024px */ } }
      header .main-navbar .main-navbar-right .mobile-languages {
        width: 100%;
        margin-top: 40px; }
        @media (min-width: 1025px) {
          header .main-navbar .main-navbar-right .mobile-languages {
            display: none;
            /* min 1025px */ } }
        header .main-navbar .main-navbar-right .mobile-languages .mobile-languages-list {
          margin: 0;
          text-align: center; }
          header .main-navbar .main-navbar-right .mobile-languages .mobile-languages-list > li {
            padding-left: 10px;
            padding-right: 10px; }
            header .main-navbar .main-navbar-right .mobile-languages .mobile-languages-list > li > a {
              font-size: 0.92em;
              line-height: 12px;
              color: #232323;
              text-transform: uppercase;
              border: 1px solid #232323;
              padding: 10px 20px; }
              header .main-navbar .main-navbar-right .mobile-languages .mobile-languages-list > li > a:hover, header .main-navbar .main-navbar-right .mobile-languages .mobile-languages-list > li > a:focus {
                text-decoration: none; }
            header .main-navbar .main-navbar-right .mobile-languages .mobile-languages-list > li.active > a {
              background-color: #232323;
              color: #fff; }
      header .main-navbar .main-navbar-right .social-list {
        float: left;
        margin-left: 0;
        margin-bottom: 0;
        padding: 5px 0; }
        @media (max-width: 1024px) {
          header .main-navbar .main-navbar-right .social-list {
            float: none;
            text-align: center;
            padding: 0;
            /* max 1024px */ } }
        header .main-navbar .main-navbar-right .social-list li {
          padding-left: 20px;
          padding-right: 0; }
          @media (max-width: 1024px) {
            header .main-navbar .main-navbar-right .social-list li {
              padding-left: 15px;
              padding-right: 15px;
              /* max 1024px */ } }
          header .main-navbar .main-navbar-right .social-list li a .social-icon {
            display: block;
            font-size: 20px;
            color: #232323; }
            header .main-navbar .main-navbar-right .social-list li a .social-icon:hover {
              color: #eaaa2a; }
    header .main-navbar .search {
      float: left; }
      @media (max-width: 1024px) {
        header .main-navbar .search {
          float: none;
          position: absolute;
          top: 20px;
          border-bottom: 3px solid #232323;
          padding: 0 20px 20px 20px;
          width: 100%;
          left: 0;
          /* max 1024px */ } }
      header .main-navbar .search .search-input-group {
        padding: 0;
        width: 175px;
        height: 30px;
        position: relative; }
        @media (max-width: 1024px) {
          header .main-navbar .search .search-input-group {
            float: none;
            width: 100%;
            /* max 1024px */ } }
        header .main-navbar .search .search-input-group input {
          position: absolute;
          width: 0px;
          z-index: 0;
          margin-left: 145px;
          transition: all 1s ease;
          -moz-transition: all 1s ease;
          -o-transition: all 1s ease;
          -ms-transition: all 1s ease;
          -webkit-transition: all 1s ease;
          height: 30px;
          outline: none; }
          @media (max-width: 1024px) {
            header .main-navbar .search .search-input-group input {
              width: 100%;
              margin-left: 0;
              /* max 1024px */ } }
          @media (min-width: 1025px) {
            header .main-navbar .search .search-input-group input {
              /* min 1025px */ }
              header .main-navbar .search .search-input-group input:focus {
                width: 145px;
                margin-left: 0px; }
                header .main-navbar .search .search-input-group input:focus + .input-group-btn .search-btn {
                  background-image: url(../../dist/images/icons/search-white.svg);
                  background-color: #232323; }
                  header .main-navbar .search .search-input-group input:focus + .input-group-btn .search-btn:hover {
                    background-image: url(../../dist/images/icons/search-yellow.svg); } }
        @media (min-width: 1025px) {
          header .main-navbar .search .search-input-group {
            /* min 1025px */ }
            header .main-navbar .search .search-input-group:hover input, header .main-navbar .search .search-input-group:hover input:focus {
              width: 145px;
              margin-left: 0px; }
            header .main-navbar .search .search-input-group:hover .search-btn {
              background-image: url(../../dist/images/icons/search-white.svg);
              background-color: #232323; } }
        header .main-navbar .search .search-input-group .search-btn {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
          background-image: url(../../dist/images/icons/search.svg);
          border: 1px solid #232323;
          background-color: #fff; }
          @media (max-width: 1024px) {
            header .main-navbar .search .search-input-group .search-btn {
              background-image: url(../../dist/images/icons/search-white.svg);
              background-color: #232323;
              /* max 1024px */ } }

header + .content {
  padding-top: 50px; }
  @media (min-width: 1025px) {
    header + .content {
      padding-top: 90px;
      /* min 1025px */ } }

.school-header {
  margin-bottom: 30px;
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.15), -5px 0 5px -5px rgba(0, 0, 0, 0.15), inset 0 -3px 0 0 #474747;
  -moz-box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.15), -5px 0 5px -5px rgba(0, 0, 0, 0.15), inset 0 -3px 0 0 #474747;
  -webkit-box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.15), -5px 0 5px -5px rgba(0, 0, 0, 0.15), inset 0 -3px 0 0 #474747; }
  @media (max-width: 767px) {
    .school-header {
      box-shadow: inset 0 -3px 0 0 #474747;
      -moz-box-shadow: inset 0 -3px 0 0 #474747;
      -webkit-box-shadow: inset 0 -3px 0 0 #474747;
      /* max 767px*/ } }
  .school-header .page-title {
    margin-top: 0 !important; }
  .school-header .school-menu {
    height: 100%;
    position: relative;
    margin-top: 10px;
    padding: 10px 35px 0 35px; }
    @media (max-width: 1024px) {
      .school-header .school-menu {
        overflow: auto;
        /* max 1024px */ }
        .school-header .school-menu ::-webkit-scrollbar {
          display: none; } }
    @media (max-width: 767px) {
      .school-header .school-menu {
        padding: 10px 0 0 10px;
        /* max 767px*/ } }
    .school-header .school-menu .page-title {
      padding: 35px; }
    .school-header .school-menu .school-menu-list {
      list-style: none outside none;
      white-space: nowrap;
      margin: 0; }
      @media (max-width: 1024px) {
        .school-header .school-menu .school-menu-list {
          overflow: auto;
          /* max 1024px */ } }
      .school-header .school-menu .school-menu-list > li {
        padding-left: 8px;
        padding-right: 8px;
        height: 26px;
        border-bottom: 3px solid #474747; }
        @media (min-width: 1025px) {
          .school-header .school-menu .school-menu-list > li {
            float: left;
            /* min 1025px */ } }
        .school-header .school-menu .school-menu-list > li > a {
          padding: 0 5px 11px 5px;
          font-size: 0.92em;
          line-height: 12px;
          text-transform: uppercase;
          color: #232323; }
          .school-header .school-menu .school-menu-list > li > a:hover, .school-header .school-menu .school-menu-list > li > a:focus, .school-header .school-menu .school-menu-list > li > a:active {
            text-decoration: none; }
        .school-header .school-menu .school-menu-list > li.active {
          border-bottom: 3px solid #eaaa2a; }
          .school-header .school-menu .school-menu-list > li.active > a {
            color: #eaaa2a; }
        @media (min-width: 1025px) {
          .school-header .school-menu .school-menu-list > li:hover {
            border-bottom: 3px solid #eaaa2a;
            /* min 1025px */ }
            .school-header .school-menu .school-menu-list > li:hover > a {
              color: #eaaa2a; } }

.school-header + .filters {
  padding-top: 0; }

.video-block {
  height: calc(100vh - 90px);
  position: relative;
  position: relative;
  overflow: hidden; }
  .video-block:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.34);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.34), rgba(255, 255, 255, 0));
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.34), rgba(255, 255, 255, 0));
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0.34), rgba(255, 255, 255, 0));
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.34), rgba(255, 255, 255, 0));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.34), rgba(255, 255, 255, 0)); }
  .video-block:after {
    content: '';
    padding-top: 100vh; }
  @media (max-width: 1024px) {
    .video-block {
      height: calc(100vh - 50px);
      /* max 1024px */ } }

.video-bg, .video, .canvas {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background-size: cover; }

.video-info-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 510px;
  min-height: 270px;
  padding: 50px 45px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.25));
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.25));
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.25));
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.25));
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.25)); }
  @media (max-width: 767px) {
    .video-info-box {
      width: calc(100% - 30px);
      height: auto;
      padding: 30px 20px;
      /* max 767px*/ } }
  .video-info-box .video-title {
    color: #fff;
    font-size: 3.85em;
    margin-top: 30px;
    margin-bottom: 20px; }
  .video-info-box .video-text {
    color: #fff;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden; }

.flexbox-slider {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  width: 100%;
  visibility: hidden; }
  @media (max-width: 1024px) {
    .flexbox-slider {
      display: block;
      /* max 1024px */ } }
  @media (min-width: 768px) and (max-width: 1024px) {
    .flexbox-slider {
      display: block;
      position: relative;
      height: 800px;
      /* min 768 - max 1024px */ } }
  .flexbox-slider .flexbox-slide {
    position: relative;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: linear;
    transition-delay: 0s;
    width: 25%;
    height: 100%;
    position: relative;
    overflow: hidden;
    visibility: visible;
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 400px; }
    .flexbox-slider .flexbox-slide:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.7);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0));
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0));
      background: -o-linear-gradient(top, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0));
      background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0));
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0)); }
    @media (max-width: 767px) {
      .flexbox-slider .flexbox-slide {
        width: 100%;
        /* max 767px*/ } }
    @media (min-width: 768px) and (max-width: 1024px) {
      .flexbox-slider .flexbox-slide {
        width: 50%;
        float: left;
        position: absolute;
        /* min 768 - max 1024px */ }
        .flexbox-slider .flexbox-slide:nth-child(1) {
          left: 0;
          top: 0; }
        .flexbox-slider .flexbox-slide:nth-child(2) {
          right: 0;
          top: 0; }
        .flexbox-slider .flexbox-slide:nth-child(3) {
          left: 0;
          top: 400px; }
        .flexbox-slider .flexbox-slide:nth-child(4) {
          right: 0;
          top: 400px; } }
    .flexbox-slider .flexbox-slide .slider-title {
      position: absolute;
      top: 30px;
      left: 30px;
      width: 100px;
      height: 70px; }
      @media (max-width: 767px) {
        .flexbox-slider .flexbox-slide .slider-title {
          /* max 767px*/ }
          .flexbox-slider .flexbox-slide .slider-title:after {
            content: '';
            height: 3px;
            width: 33px;
            background-color: #eaaa2a;
            position: absolute;
            left: 0;
            top: 100%;
            margin-top: 10px;
            transition-property: all;
            transition-duration: 0.2s;
            transition-timing-function: linear;
            transition-delay: 0.5s; } }
      .flexbox-slider .flexbox-slide .slider-title h1 {
        color: #fff;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1.54em;
        line-height: 24px; }
      .flexbox-slider .flexbox-slide .slider-title img {
        width: 100%;
        height: auto; }
    .flexbox-slider .flexbox-slide .text-block {
      position: absolute;
      top: 110px;
      left: 30px;
      max-width: 270px;
      z-index: 4;
      visibility: hidden;
      padding-top: 20px; }
      @media (max-width: 1024px) {
        .flexbox-slider .flexbox-slide .text-block {
          visibility: visible;
          /* max 1024px */ } }
      .flexbox-slider .flexbox-slide .text-block .slide-info {
        color: #fff;
        font-size: 1em;
        line-height: 22px;
        text-overflow: ellipsis;
        -webkit-line-clamp: 6;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 30px; }
  @media (min-width: 1025px) {
    .flexbox-slider {
      /* min 1025px */ }
      .flexbox-slider .flexbox-slide:hover {
        flex-shrink: 0;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        width: 50%; }
        .flexbox-slider .flexbox-slide:hover .slider-title:after {
          content: '';
          height: 3px;
          width: 33px;
          background-color: #eaaa2a;
          position: absolute;
          left: 0;
          top: 100%;
          margin-top: 10px;
          transition-property: all;
          transition-duration: 0.2s;
          transition-timing-function: linear;
          transition-delay: 0.5s; }
        .flexbox-slider .flexbox-slide:hover .text-block {
          transition-property: all;
          transition-duration: 0.2s;
          transition-timing-function: linear;
          transition-delay: 0.5s;
          opacity: 1;
          visibility: visible; } }

.page-title {
  padding: 35px; }
  .page-title h1 {
    font-size: 1.3em;
    line-height: 17px;
    text-transform: uppercase;
    color: #232323;
    border-left: 3px solid #eaaa2a;
    padding: 10px 0 10px 20px;
    margin-top: 0;
    margin-bottom: 0; }

.page-title-with-subtitle {
  margin: 35px;
  border-left: 3px solid #eaaa2a; }
  .page-title-with-subtitle h1 {
    font-size: 1.3em;
    line-height: 17px;
    text-transform: uppercase;
    color: #232323;
    padding-left: 20px;
    margin-top: 0;
    margin-bottom: 10px; }
  .page-title-with-subtitle .subtitle {
    padding-left: 20px;
    font-size: 0.92em;
    line-height: 12px; }
    .page-title-with-subtitle .subtitle span + span {
      margin-left: 3px;
      position: relative;
      padding-left: 6px; }
      .page-title-with-subtitle .subtitle span + span:before {
        content: '|';
        position: absolute;
        left: 0; }

.school-title {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 35px; }
  @media (max-width: 767px) {
    .school-title {
      padding-left: 0;
      /* max 767px*/ } }
  .school-title .icon {
    width: 42px;
    height: 42px;
    background-color: #eaaa2a;
    background-size: 50% 50%;
    float: left; }
  .school-title h1 {
    padding-left: 62px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.3em;
    line-height: 17px;
    padding-top: 5px;
    color: #232323;
    text-transform: uppercase; }

.school-title-with-subtitle {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 35px; }
  @media (max-width: 767px) {
    .school-title-with-subtitle {
      padding-left: 0;
      /* max 767px*/ } }
  .school-title-with-subtitle .icon {
    width: 42px;
    height: 42px;
    background-color: #eaaa2a;
    background-size: 50% 50%;
    float: left; }
  .school-title-with-subtitle h1.title {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 1.3em;
    line-height: 17px;
    color: #232323;
    text-transform: uppercase;
    padding-left: 62px; }
  .school-title-with-subtitle .subtitle {
    font-size: 0.92em;
    line-height: 12px;
    padding-left: 62px; }
    .school-title-with-subtitle .subtitle span + span {
      position: relative;
      margin-left: 4px;
      padding-left: 5px; }
      .school-title-with-subtitle .subtitle span + span:before {
        content: '|';
        position: absolute;
        left: 0; }

@media (max-width: 767px) {
  .container-small .page-title {
    padding: 35px 0;
    /* max 767px*/ } }

@media (max-width: 767px) {
  .container-small .page-title-with-subtitle {
    margin: 35px 0;
    /* max 767px*/ } }

.btn {
  border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -webkit-border-radius: 0;
  background-color: transparent;
  color: #fff;
  font-size: 1.08em;
  line-height: 14px;
  text-transform: uppercase;
  padding: 12px 15px;
  min-width: 100px;
  border: 1px solid #fff; }
  .btn.btn-dark {
    background-color: #232323;
    border-color: #232323; }
  .btn:hover {
    background-color: #eaaa2a;
    border-color: #eaaa2a;
    color: #fff; }
  .btn:focus, .btn:active, .btn:hover {
    text-decoration: none;
    box-shadow: none;
    outline: none;
    color: #fff; }

.show-more-btn {
  display: block;
  margin-bottom: 20px;
  font-size: 0.92em;
  line-height: 12px;
  color: #eaaa2a;
  text-transform: uppercase; }
  .show-more-btn:hover, .show-more-btn:focus {
    text-decoration: none;
    color: #eaaa2a; }

.bg-cover {
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  width: 100%;
  height: 100%;
  position: relative; }
  .bg-cover:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.34);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.34), rgba(255, 255, 255, 0));
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.34), rgba(255, 255, 255, 0));
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0.34), rgba(255, 255, 255, 0));
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.34), rgba(255, 255, 255, 0));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.34), rgba(255, 255, 255, 0)); }

.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 50% 50%; }

.photo-icon {
  background-image: url(../../dist/images/icons/photo-camera.svg);
  width: 20px;
  height: 15px; }

.video-icon {
  background-image: url(../../dist/images/icons/video-camera.svg);
  width: 20px;
  height: 15px; }

.article-icon {
  background-image: url(../../dist/images/icons/article.svg);
  width: 20px;
  height: 15px; }

.video-holder {
  margin: 30px 0; }

.item-row {
  padding-bottom: 10px; }
  @media (min-width: 768px) {
    .item-row {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      /* min 768px */ } }
  .item-row:not(:first-child) {
    padding-top: 30px; }

.item-heading-box {
  padding: 10px 10px 10px 0;
  margin-left: 10px; }

.item-heading {
  height: 42px;
  border-left: 3px solid #eaaa2a; }
  .item-heading a:hover, .item-heading a:focus, .item-heading a:active {
    text-decoration: none; }
  .item-heading .item-title {
    padding: 2px 10px;
    font-size: 1.3em;
    line-height: 17px;
    color: #232323;
    margin: 0;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 36px; }

.item-heading-with-subtitle-box {
  padding: 10px 10px 10px 0;
  margin-left: 10px; }

.item-heading-with-subtitle {
  height: 65px;
  border-left: 3px solid #eaaa2a;
  padding: 4px 10px; }
  .item-heading-with-subtitle a:hover, .item-heading-with-subtitle a:focus, .item-heading-with-subtitle a:active {
    text-decoration: none; }
  .item-heading-with-subtitle .item-title {
    font-size: 1.3em;
    line-height: 17px;
    color: #232323;
    margin: 0 0 4px 0;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 36px;
    padding: 2px 0; }

.item-heading-with-subtitle-absolute {
  margin: 10px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease; }

.item-subtitle {
  color: #474747;
  font-size: 0.92em;
  line-height: 12px; }
  .item-subtitle span:not(.icon) + span {
    margin-left: 3px; }
    .item-subtitle span:not(.icon) + span:before {
      content: '|';
      margin-right: 3px; }

@media (min-width: 992px) {
  .item-col-bordered {
    margin-bottom: 30px;
    /* min 992px */ }
    .item-col-bordered:nth-child(3n+1):after {
      content: '';
      height: 1px;
      background-color: rgba(0, 0, 0, 0.15);
      width: 860px;
      position: absolute;
      bottom: 0;
      left: 10px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .item-col-bordered {
    /* min 768px -max 991px */ }
    .item-col-bordered:nth-child(3n+1):after {
      width: 750px; } }

.item-col .item-box {
  margin-bottom: 20px;
  position: relative;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease; }
  .item-col .item-box:hover {
    box-shadow: 2px -1px 15px 4px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 2px -1px 15px 4px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 2px -1px 15px 4px rgba(0, 0, 0, 0.15); }
    .item-col .item-box:hover .item-img .img-holder {
      transform: scale(1.2);
      -moz-transform: scale(1.2);
      -o-transform: scale(1.2);
      -ms-transform: scale(1.2);
      /* IE 9 */
      -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1.5, M12=0, M21=0, M22=1.5, SizingMethod='auto expand')";
      /* IE8 */
      filter: progid:DXImageTransform.Microsoft.Matrix(M11=1.5, M12=0, M21=0, M22=1.5, SizingMethod='auto expand');
      /* IE6 and 7 */
      -webkit-transform: scale(1.2); }
    .item-col .item-box:hover .item-heading-box {
      border-left: 3px solid #eaaa2a; }
      .item-col .item-box:hover .item-heading-box .item-heading {
        border: none; }
    .item-col .item-box:hover .item-heading-with-subtitle-box {
      border-left: 3px solid #eaaa2a; }
      .item-col .item-box:hover .item-heading-with-subtitle-box .item-heading-with-subtitle {
        border: none; }
    .item-col .item-box:hover .item-heading-with-subtitle-absolute {
      height: auto;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 14px;
      padding-bottom: 14px; }
    .item-col .item-box:hover .item-text {
      border-color: #eaaa2a; }

.item-col .item-img {
  width: 100%;
  position: relative;
  overflow: hidden; }
  .item-col .item-img .img-holder {
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 175px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .item-col .item-img .img-holder {
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 245px;
        /* min 768px -max 991px */ } }
    @media (min-width: 600px) and (max-width: 767px) {
      .item-col .item-img .img-holder {
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 300px;
        /* min 600px -max 767px */ } }
    @media (min-width: 480px) and (max-width: 599px) {
      .item-col .item-img .img-holder {
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 250px;
        /* min 480px -max 599px */ } }
    @media (min-width: 321px) and (max-width: 479px) {
      .item-col .item-img .img-holder {
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 200px;
        /* min 321px -max 479px */ } }
    @media (max-width: 320px) {
      .item-col .item-img .img-holder {
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 185px;
        /* max 320px */ } }

.item-col a, .item-col-bordered a {
  display: block;
  font-family: inherit !important;
  font-weight: normal !important; }

.item-col a, .item-col a:focus, .item-col a:active, .item-col a:visited, .item-col-bordered a, .item-col-bordered a:focus, .item-col-bordered a:active, .item-col-bordered a:visited {
  text-decoration: none !important;
  color: inherit; }

.item-col:hover a, .item-col:hover a:focus, .item-col:hover a:active, .item-col:hover a:visited, .item-col-bordered:hover a, .item-col-bordered:hover a:focus, .item-col-bordered:hover a:active, .item-col-bordered:hover a:visited {
  text-decoration: none !important;
  color: inherit; }

.item-subtitle-absolute {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fff; }
  .item-subtitle-absolute .icon {
    margin-right: 10px; }

.item-text {
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  border-left: 3px solid transparent;
  margin-left: 10px;
  padding-bottom: 10px; }
  .item-text p {
    height: 86px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding: 0 10px 0 14px;
    margin: 0; }

.full-width-block .full-width-col {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden; }
  .full-width-block .full-width-col .full-width-img {
    width: 100%; }
    .full-width-block .full-width-col .full-width-img .img-holder {
      width: 100%;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      height: 320px;
      transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      position: relative; }
      .full-width-block .full-width-col .full-width-img .img-holder:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.34);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.34), rgba(255, 255, 255, 0));
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.34), rgba(255, 255, 255, 0));
        background: -o-linear-gradient(top, rgba(0, 0, 0, 0.34), rgba(255, 255, 255, 0));
        background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.34), rgba(255, 255, 255, 0));
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.34), rgba(255, 255, 255, 0)); }
      .full-width-block .full-width-col .full-width-img .img-holder:hover {
        transform: scale(1.2);
        -moz-transform: scale(1.2);
        -o-transform: scale(1.2);
        -ms-transform: scale(1.2);
        /* IE 9 */
        -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1.5, M12=0, M21=0, M22=1.5, SizingMethod='auto expand')";
        /* IE8 */
        filter: progid:DXImageTransform.Microsoft.Matrix(M11=1.5, M12=0, M21=0, M22=1.5, SizingMethod='auto expand');
        /* IE6 and 7 */
        -webkit-transform: scale(1.2); }
  .full-width-block .full-width-col .full-width-info {
    position: absolute;
    top: 30px;
    left: 30px; }
    @media (max-width: 991px) {
      .full-width-block .full-width-col .full-width-info {
        left: 20px;
        /* max 991px */ } }
    .full-width-block .full-width-col .full-width-info a:hover, .full-width-block .full-width-col .full-width-info a:focus, .full-width-block .full-width-col .full-width-info a:active {
      text-decoration: none; }
    .full-width-block .full-width-col .full-width-info .full-width-title {
      width: 174px;
      color: #fff;
      font-size: 1.54em;
      line-height: 24px;
      margin-bottom: 25px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 72px; }
    .full-width-block .full-width-col .full-width-info .full-width-subtitle {
      width: 260px;
      color: #fff;
      font-size: 1em;
      line-height: 20px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 6;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 120px; }
      @media (max-width: 991px) {
        .full-width-block .full-width-col .full-width-info .full-width-subtitle {
          width: calc(100% - 20px);
          /* max 991px */ } }

.owl-carousel .owl-nav .disabled {
  display: none; }

.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 50%; }
  .owl-carousel .owl-nav .owl-prev .slider-arrow, .owl-carousel .owl-nav .owl-next .slider-arrow {
    display: block;
    border: 1px solid #232323;
    background-color: #fff;
    width: 35px;
    height: 35px;
    background-image: url(../../dist/images/icons/arrow.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 50% 50%; }
    .owl-carousel .owl-nav .owl-prev .slider-arrow:hover, .owl-carousel .owl-nav .owl-next .slider-arrow:hover {
      background-image: url(../../dist/images/icons/arrow-white.svg);
      background-color: #eaaa2a;
      border-color: #eaaa2a; }

.owl-carousel .owl-nav .owl-prev {
  left: 0;
  margin-left: -55px; }
  @media (max-width: 991px) {
    .owl-carousel .owl-nav .owl-prev {
      margin-left: 0;
      /* max 991px */ } }
  .owl-carousel .owl-nav .owl-prev .slider-arrow {
    transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg); }
    .owl-carousel .owl-nav .owl-prev .slider-arrow:hover {
      transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      -webkit-transform: rotate(270deg); }

.owl-carousel .owl-nav .owl-next {
  right: 0;
  margin-right: -55px; }
  @media (max-width: 991px) {
    .owl-carousel .owl-nav .owl-next {
      margin-right: 0;
      /* max 991px */ } }
  .owl-carousel .owl-nav .owl-next .slider-arrow {
    transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -webkit-transform: rotate(270deg); }
    .owl-carousel .owl-nav .owl-next .slider-arrow:hover {
      transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg); }

.expeditions-slider {
  width: 100%; }
  .expeditions-slider .slider-item .img-holder {
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 350px; }
    @media (min-width: 480px) and (max-width: 767px) {
      .expeditions-slider .slider-item .img-holder {
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 300px;
        /*min 480px- max 767px */ } }
    @media (min-width: 321px) and (max-width: 479px) {
      .expeditions-slider .slider-item .img-holder {
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 250px;
        /* min 321px -max 479px */ } }
    @media (max-width: 320px) {
      .expeditions-slider .slider-item .img-holder {
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 200px;
        /* max 320px */ } }

.expeditions-item .expeditions-img {
  width: 100%; }
  .expeditions-item .expeditions-img .img-holder {
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 190px;
    position: relative;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease; }
    .expeditions-item .expeditions-img .img-holder:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(255, 255, 255, 0);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.34));
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.34));
      background: -o-linear-gradient(top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.34));
      background: -ms-linear-gradient(top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.34));
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.34)); }
    @media (min-width: 768px) and (max-width: 991px) {
      .expeditions-item .expeditions-img .img-holder {
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 245px;
        /* min 768px -max 991px */ } }
    @media (min-width: 600px) and (max-width: 767px) {
      .expeditions-item .expeditions-img .img-holder {
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 300px;
        /* min 600px -max 767px */ } }
    @media (min-width: 480px) and (max-width: 599px) {
      .expeditions-item .expeditions-img .img-holder {
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 250px;
        /* min 480px -max 599px */ } }
    @media (min-width: 321px) and (max-width: 479px) {
      .expeditions-item .expeditions-img .img-holder {
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 200px;
        /* min 321px -max 479px */ } }
    @media (max-width: 320px) {
      .expeditions-item .expeditions-img .img-holder {
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        height: 185px;
        /* max 320px */ } }

.expeditions-item .item-heading-with-subtitle {
  position: absolute;
  bottom: 0;
  height: auto; }
  .expeditions-item .item-heading-with-subtitle .item-title, .expeditions-item .item-heading-with-subtitle .item-subtitle {
    color: #fff;
    height: auto; }

.expeditions-item:hover .item-heading-with-subtitle {
  height: auto; }

.class-block {
  padding: 0 35px;
  margin-bottom: 10px; }
  .class-block .show-more-btn {
    display: none; }
    @media (max-width: 767px) {
      .class-block .show-more-btn {
        display: block;
        /* max 767px*/ } }
  .class-block .row, .class-block .class-row {
    margin-left: -2px;
    margin-right: -2px; }
  .class-block .class-col {
    padding-left: 2px;
    padding-right: 2px; }
  .class-block .class-title {
    font-size: 1.3em;
    text-transform: uppercase;
    color: #232323;
    margin-top: 0;
    margin-bottom: 20px; }
  .class-block .class-list {
    margin-bottom: 0; }
    .class-block .class-list li {
      font-size: 0.92em;
      line-height: 14px;
      height: 30px; }
      .class-block .class-list li a {
        color: #232323;
        font-weight: bold;
        font-size: 1.08em; }
        .class-block .class-list li a:hover, .class-block .class-list li a:focus, .class-block .class-list li a:active {
          text-decoration: none;
          color: #eaaa2a; }
      .class-block .class-list li span {
        display: inline-block;
        color: #474747;
        position: relative;
        margin-left: 3px;
        padding-left: 6px; }
        .class-block .class-list li span:before {
          content: '-';
          position: absolute;
          left: 0; }

.works-block .item-col .img-holder, .gallery-block .item-col .img-holder {
  position: relative; }
  .works-block .item-col .img-holder:before, .gallery-block .item-col .img-holder:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.34));
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.34));
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.34));
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.34));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.34)); }

.search-title h1 {
  float: left; }

.search-input-group {
  width: 175px;
  float: right;
  padding: 6px 0; }
  .search-input-group .search-input {
    border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -webkit-border-radius: 0;
    border: 1px solid #232323;
    box-shadow: none;
    height: 30px; }
  .search-input-group .search-btn {
    display: block;
    width: 30px;
    height: 30px;
    min-width: auto;
    border: 1px solid #232323;
    background-image: url(../../dist/images/icons/search-white.svg);
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position: 50% 50%;
    border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -webkit-border-radius: 0;
    background-color: #232323; }
    .search-input-group .search-btn:hover {
      background-image: url(../../dist/images/icons/search-yellow.svg); }

.search-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 20px; }
  .search-row:not(:first-child) {
    padding-top: 20px; }
  .search-row .search-item .search-img {
    width: 200px;
    float: left; }
    @media (max-width: 767px) {
      .search-row .search-item .search-img {
        float: none;
        width: 100%;
        margin-bottom: 10px;
        /* max 767px*/ } }
    .search-row .search-item .search-img .img-holder {
      width: 100%;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      height: 130px; }
      @media (max-width: 767px) {
        .search-row .search-item .search-img .img-holder {
          width: 100%;
          background-size: cover;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          height: 185px;
          /* max 767px*/ } }
  .search-row .search-item .search-img + .search-info {
    margin-left: 200px;
    padding-left: 20px; }
    @media (max-width: 767px) {
      .search-row .search-item .search-img + .search-info {
        margin-left: 0;
        padding-left: 0;
        /* max 767px*/ } }
  .search-row .search-item .search-info .item-heading-with-subtitle {
    height: auto;
    border: none;
    padding: 4px 0; }
    .search-row .search-item .search-info .item-heading-with-subtitle .item-title {
      margin: 0 0 10px 0;
      height: auto; }
  .search-row .search-item .search-info .search-text {
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 70px; }

/* LAYOUTS
========================================================================== */
@media (min-width: 768px) {
  .container {
    padding-left: 0;
    padding-right: 0;
    /* min 768px */ } }

.row {
  margin-left: -10px;
  margin-right: -10px; }

.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  padding-left: 10px;
  padding-right: 10px; }

/*****************************************************Global ********************************************/
html {
  min-height: 100%;
  position: relative; }

body {
  background: #fff;
  font-size: 13px;
  line-height: 17px;
  color: #474747;
  width: 100%; }

html {
  height: 100%; }

body {
  position: relative;
  min-height: 100%; }

[class*='icon-']:before {
  display: inline-block;
  font-family: 'gomedia';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-facebook:before {
  content: '\0041'; }

.icon-youtube:before {
  content: '\0042'; }

.icon-vimeo:before {
  content: '\0043'; }

[v-cloak] {
  display: none; }

@media (min-width: 992px) {
  .container-small {
    width: 638px;
    /* min 992px */ }
    .container-small .container-big {
      margin-left: -111px; } }

.container-small .container-big {
  padding-left: 0;
  padding-right: 0; }
  @media (min-width: 992px) {
    .container-small .container-big {
      margin-left: -110px;
      /* min 992px */ } }

@media (min-width: 992px) {
  .container-big {
    width: 860px;
    /* min 992px */ } }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 20px; }

a {
  color: #232323;
  font-size: 1em; }
  a:hover, a:focus {
    color: #eaaa2a;
    outline: none; }

a + a:before {
  content: '|';
  margin-left: 5px;
  margin-right: 5px;
  color: #474747; }

ul {
  margin-bottom: 20px; }

p {
  padding: 0 35px;
  margin: 0 0 20px 0;
  font-size: 1em;
  line-height: 17px;
  color: #474747; }
  @media (max-width: 767px) {
    p {
      padding: 0;
      /* max 767px*/ }
      p img {
        margin: 0;
        width: 100%;
        max-width: 100%; } }
  p img {
    margin: 0 0 20px -35px;
    width: calc(100% + 70px);
    height: auto;
    max-width: calc(100% + 70px); }
    @media (max-width: 767px) {
      p img {
        width: 100%;
        height: auto;
        max-width: 100%;
        margin: 0 0 20px 0;
        /* max 767px*/ } }

p.video-holder {
  margin: 0 0 20px 0;
  position: relative;
  padding: 0 0 56.25% 0;
  height: 0;
  overflow: hidden; }

p.video-holder iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.bg-img {
  margin-top: 30px;
  margin-bottom: 30px; }
  .bg-img .img-holder {
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 300px; }

.container-small .page-title {
  margin-top: 20px; }

.preloader {
  margin-top: 40px;
  margin-bottom: 60px; }
  .preloader .preloader-icon {
    width: 30px;
    height: 30px;
    background-image: url(../../dist/images/icons/preloader.gif);
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    display: block; }

.no-result {
  text-align: center;
  font-size: 1.54em; }

html[lang="en-US"] body {
  font-family: "Assistant-Regular"; }
  html[lang="en-US"] body a {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body p {
    font-family: "Assistant-Regular"; }
  html[lang="en-US"] body h1, html[lang="en-US"] body h2, html[lang="en-US"] body h3, html[lang="en-US"] body h4, html[lang="en-US"] body h5, html[lang="en-US"] body h6 {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body input[type="submit"] {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .contact-form-block input::placeholder, html[lang="en-US"] body .contact-form-block textarea::placeholder {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .contact-form-block input::-webkit-input-placeholder, html[lang="en-US"] body .contact-form-block textarea::-webkit-input-placeholder {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .contact-form-block input::-moz-placeholder, html[lang="en-US"] body .contact-form-block textarea::-moz-placeholder {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .contact-form-block input::-moz-placeholder, html[lang="en-US"] body .contact-form-block textarea::-moz-placeholder {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .contact-form-block input::-ms-input-placeholder, html[lang="en-US"] body .contact-form-block textarea::-ms-input-placeholder {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .main-menu-list > li > a, html[lang="en-US"] body .submenu > li > a, html[lang="en-US"] body .school-menu-list > li > a, html[lang="en-US"] body .mobile-languages-list > li > a {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .dropdown-bordered a.dropdown-toggle {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .dropdown-bordered .dropdown-menu > li > a {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .dropdown-bordered.languages-dropdown a.dropdown-toggle {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .dropdown-bordered.languages-dropdown .dropdown-menu.languages-list > li > a {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .filter .dropdown .selected-tag {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .filter .dropdown .dropdown-menu > li > a {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .filter.author-filter .dropdown .dropdown-menu > li > a {
    font-family: "Assistant-Regular"; }
  html[lang="en-US"] body .school-title h1, html[lang="en-US"] body .school-title h1.title, html[lang="en-US"] body .school-title-with-subtitle h1, html[lang="en-US"] body .school-title-with-subtitle h1.title {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .show-more-btn, html[lang="en-US"] body .btn {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .item-heading .item-title, html[lang="en-US"] body .item-heading-with-subtitle .item-title {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .item-heading .item-subtitle, html[lang="en-US"] body .item-heading-with-subtitle .item-subtitle {
    font-family: "Assistant-Regular"; }
  html[lang="en-US"] body .full-width-col .full-width-subtitle {
    font-family: "Antonio-Bold"; }
  html[lang="en-US"] body .video-block .video-title {
    font-family: "Antonio-Bold"; }
  html[lang="en-US"] body .flexbox-slider .flexbox-slide .slider-title h1 {
    font-family: "Antonio-Bold"; }
  html[lang="en-US"] body .trainers-grid .trainer-name {
    font-family: "Antonio-Bold"; }
  html[lang="en-US"] body .class-block .class-title {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .class-block .class-list li a {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .contact-form-block .contact-form .form-control {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .contact-info-block .contact-info span {
    font-family: "Assistant-Bold"; }
  html[lang="en-US"] body .block-404 .box-404 .txt-404 {
    font-family: "Antonio-Bold"; }
  html[lang="en-US"] body .block-404 .box-404 .box-404-info .not-found-txt {
    font-family: "Antonio-Bold"; }
  html[lang="en-US"] body footer .copyright {
    font-family: "Assistant-Regular"; }

html[lang="ru-RU"] body {
  font-family: "Roboto-Light"; }
  html[lang="ru-RU"] body p {
    font-family: "Roboto-Light"; }
  html[lang="ru-RU"] body a {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body h1, html[lang="ru-RU"] body h2, html[lang="ru-RU"] body h3, html[lang="ru-RU"] body h4, html[lang="ru-RU"] body h5, html[lang="ru-RU"] body h6 {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body input[type="submit"] {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body .contact-form-block input::placeholder, html[lang="ru-RU"] body .contact-form-block textarea::placeholder {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body .contact-form-block input::-webkit-input-placeholder, html[lang="ru-RU"] body .contact-form-block textarea::-webkit-input-placeholder {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body .contact-form-block input::-moz-placeholder, html[lang="ru-RU"] body .contact-form-block textarea::-moz-placeholder {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body .contact-form-block input::-moz-placeholder, html[lang="ru-RU"] body .contact-form-block textarea::-moz-placeholder {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body .contact-form-block input::-ms-input-placeholder, html[lang="ru-RU"] body .contact-form-block textarea::-ms-input-placeholder {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body .video-info-box .video-title {
    font-size: 3.5em; }
  html[lang="ru-RU"] body .main-menu-list > li > a, html[lang="ru-RU"] body .submenu > li > a, html[lang="ru-RU"] body .school-menu-list > li > a {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body .school-menu-list > li > a {
    text-transform: capitalize !important;
    font-size: 1em !important; }
  html[lang="ru-RU"] body .dropdown-bordered a.dropdown-toggle {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body .dropdown-bordered .dropdown-menu > li > a {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body .dropdown-bordered.languages-dropdown a.dropdown-toggle {
    font-family: "Assistant-Bold"; }
  html[lang="ru-RU"] body .dropdown-bordered.languages-dropdown .dropdown-menu.languages-list > li > a {
    font-family: "Assistant-Bold"; }
  html[lang="ru-RU"] body .mobile-languages-list > li > a {
    font-family: "Assistant-Bold"; }
  html[lang="ru-RU"] body .filter .dropdown .selected-tag {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body .filter .dropdown .dropdown-menu > li > a {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body .filter.author-filter .dropdown .dropdown-menu > li > a {
    font-family: "Roboto-Light"; }
  html[lang="ru-RU"] body .school-title h1, html[lang="ru-RU"] body .school-title h1.title, html[lang="ru-RU"] body .school-title-with-subtitle h1, html[lang="ru-RU"] body .school-title-with-subtitle h1.title {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body .show-more-btn, html[lang="ru-RU"] body .btn {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body .item-heading .item-title, html[lang="ru-RU"] body .item-heading-with-subtitle .item-title {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body .item-heading .item-subtitle, html[lang="ru-RU"] body .item-heading-with-subtitle .item-subtitle {
    font-family: "Roboto-Light"; }
  html[lang="ru-RU"] body .full-width-col .full-width-subtitle {
    font-family: "Oswald-SemiBold"; }
  html[lang="ru-RU"] body .video-block .video-title {
    font-family: "Oswald-SemiBold"; }
  html[lang="ru-RU"] body .flexbox-slider .flexbox-slide .slider-title h1 {
    font-family: "Oswald-SemiBold"; }
  html[lang="ru-RU"] body .trainers-grid .trainer-name {
    font-family: "Oswald-SemiBold"; }
  html[lang="ru-RU"] body .class-block .class-title {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body .class-block .class-list li a {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body .contact-form-block .contact-form .form-control {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body .contact-info-block .contact-info span {
    font-family: "Roboto-Bold"; }
  html[lang="ru-RU"] body .block-404 .box-404 .txt-404 {
    font-family: "Antonio-Bold"; }
  html[lang="ru-RU"] body .block-404 .box-404 .box-404-info .not-found-txt {
    font-family: "Oswald-SemiBold"; }
    @media (max-width: 767px) {
      html[lang="ru-RU"] body .block-404 .box-404 .box-404-info .not-found-txt {
        font-size: 2em;
        /* max 767px*/ } }
  html[lang="ru-RU"] body footer .copyright {
    font-family: "Assistant-Regular"; }

html[lang="ka-GE"] body {
  font-family: "BPG Nateli"; }
  html[lang="ka-GE"] body p {
    font-family: "BPG Nateli";
    font-size: 1.08em; }
  html[lang="ka-GE"] body a {
    font-family: "BPG Nateli";
    font-weight: bold; }
  html[lang="ka-GE"] body h1, html[lang="ka-GE"] body h2, html[lang="ka-GE"] body h3, html[lang="ka-GE"] body h4, html[lang="ka-GE"] body h5, html[lang="ka-GE"] body h6 {
    font-family: "BPG ExtraSquare Mtavruli";
    font-weight: bold; }
  html[lang="ka-GE"] body input[type="submit"] {
    font-family: "BPG ExtraSquare Mtavruli"; }
  html[lang="ka-GE"] body .contact-form-block input::placeholder, html[lang="ka-GE"] body .contact-form-block textarea::placeholder {
    font-family: "BPG ExtraSquare Mtavruli"; }
  html[lang="ka-GE"] body .contact-form-block input::-webkit-input-placeholder, html[lang="ka-GE"] body .contact-form-block textarea::-webkit-input-placeholder {
    font-family: "BPG ExtraSquare Mtavruli"; }
  html[lang="ka-GE"] body .contact-form-block input::-moz-placeholder, html[lang="ka-GE"] body .contact-form-block textarea::-moz-placeholder {
    font-family: "BPG ExtraSquare Mtavruli"; }
  html[lang="ka-GE"] body .contact-form-block input::-moz-placeholder, html[lang="ka-GE"] body .contact-form-block textarea::-moz-placeholder {
    font-family: "BPG ExtraSquare Mtavruli"; }
  html[lang="ka-GE"] body .contact-form-block input::-ms-input-placeholder, html[lang="ka-GE"] body .contact-form-block textarea::-ms-input-placeholder {
    font-family: "BPG ExtraSquare Mtavruli"; }
  html[lang="ka-GE"] body .video-info-box .video-title {
    font-size: 3.2em; }
  html[lang="ka-GE"] body .main-menu-list > li > a, html[lang="ka-GE"] body .submenu > li > a, html[lang="ka-GE"] body .school-menu-list > li > a {
    font-family: "BPG ExtraSquare Mtavruli"; }
  html[lang="ka-GE"] body .main-menu-list > li > a {
    font-weight: bold; }
  html[lang="ka-GE"] body .dropdown-bordered a.dropdown-toggle {
    font-family: "BPG ExtraSquare Mtavruli"; }
  html[lang="ka-GE"] body .dropdown-bordered .dropdown-menu > li > a {
    font-family: "BPG ExtraSquare Mtavruli"; }
  html[lang="ka-GE"] body .dropdown-bordered.languages-dropdown a.dropdown-toggle {
    font-family: "Assistant-Bold"; }
  html[lang="ka-GE"] body .dropdown-bordered.languages-dropdown .dropdown-menu.languages-list > li > a {
    font-family: "Assistant-Bold"; }
  html[lang="ka-GE"] body .mobile-languages-list > li > a {
    font-family: "Assistant-Bold"; }
  html[lang="ka-GE"] body .filter.select-filter .dropdown .selected-tag {
    font-family: "BPG ExtraSquare Mtavruli"; }
  html[lang="ka-GE"] body .filter.select-filter .dropdown .dropdown-menu > li > a {
    font-family: "BPG ExtraSquare Mtavruli"; }
  html[lang="ka-GE"] body .filter.author-filter .dropdown .dropdown-menu > li > a {
    font-family: "BPG Nateli"; }
  html[lang="ka-GE"] body .school-title h1, html[lang="ka-GE"] body .school-title h1.title, html[lang="ka-GE"] body .school-title-with-subtitle h1, html[lang="ka-GE"] body .school-title-with-subtitle h1.title {
    font-family: "BPG ExtraSquare Mtavruli"; }
  html[lang="ka-GE"] body .show-more-btn, html[lang="ka-GE"] body .btn {
    font-family: "BPG ExtraSquare Mtavruli"; }
  html[lang="ka-GE"] body .item-heading .item-title, html[lang="ka-GE"] body .item-heading-with-subtitle .item-title {
    font-family: "BPG ExtraSquare Mtavruli";
    max-height: 34px; }
  html[lang="ka-GE"] body .item-heading .item-subtitle, html[lang="ka-GE"] body .item-heading-with-subtitle .item-subtitle {
    font-family: "BPG Nateli"; }
  html[lang="ka-GE"] body .full-width-col .full-width-title {
    font-family: "BPG ExtraSquare Mtavruli";
    font-size: 1.3em !important; }
  html[lang="ka-GE"] body .full-width-col .full-width-subtitle {
    font-family: "BPG Nateli";
    font-size: 1.08em !important; }
  html[lang="ka-GE"] body .video-block .video-title {
    font-family: "BPG ExtraSquare Mtavruli"; }
    @media (max-width: 767px) {
      html[lang="ka-GE"] body .video-block .video-title {
        font-size: 2.5em;
        /* max 767px*/ } }
  html[lang="ka-GE"] body .flexbox-slider .flexbox-slide .slider-title h1 {
    font-family: "BPG ExtraSquare Mtavruli"; }
  html[lang="ka-GE"] body .trainers-grid .trainer-name {
    font-family: "BPG ExtraSquare Mtavruli"; }
  html[lang="ka-GE"] body .class-block .class-title {
    font-family: "BPG ExtraSquare Mtavruli"; }
  html[lang="ka-GE"] body .class-block .class-list li a {
    font-family: "BPG Nateli"; }
  html[lang="ka-GE"] body .contact-form-block .contact-form .form-control {
    font-family: "BPG ExtraSquare Mtavruli"; }
  html[lang="ka-GE"] body .contact-info-block .contact-info span {
    font-family: "BPG ExtraSquare Mtavruli"; }
  html[lang="ka-GE"] body .block-404 .box-404 .txt-404 {
    font-family: "Antonio-Bold"; }
  html[lang="ka-GE"] body .block-404 .box-404 .box-404-info .not-found-txt {
    font-family: "BPG ExtraSquare Mtavruli";
    font-size: 2.1em; }
    @media (max-width: 767px) {
      html[lang="ka-GE"] body .block-404 .box-404 .box-404-info .not-found-txt {
        font-size: 1.5em;
        /* max 767px*/ } }
  html[lang="ka-GE"] body footer .copyright {
    font-family: "Assistant-Regular"; }
