@keyframes placeHolderShimmer {
  0%{ background-position: -468px 0; }
  100%{ background-position: 468px 0; }
}

.animated-background {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeHolderShimmer;
  background: #f6f7f8;
  @include linear-gradient-horizontal(#eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.grid-load {
  background: $white;
  overflow: hidden;
  @include box-shadow(0 0 10px rgba($black,0.05));
  margin-bottom: 20px;
  height: 190px;
  @include tablet {
    height: 245px;
  }
  @include mobile-xl-lg {
    height:300px;
  }

  @include mobile-sm-xs {
    height: 200px;
  }

  .img-bg {
    width: 100%;
    height: 190px;
     @include tablet {
       height
       : 245px;
    }
    @include mobile-xl-lg {
        height:300px;
      }

      @include mobile-sm-xs {
        height: 200px;
      }
  }

  .title-bg { 
    left: 15px;
    width: calc( 100% - 60px );
    height: 10px;
    top: 20px;
  }

  .author-bg {
    left: 15px;
    width: 30%;
    height: 7px;
    top: 40px;
  }

  .description1-bg {
    width: calc( 100% - 67px );
    left: 15px;
    height: 8px;
    top:67px;
  }

  .description2-bg {
    width: calc( 100% - 30px );
    left: 15px;
    height: 8px;
    top:77px;
  }

  .description3-bg {
    width: calc( 100% - 40px );
    left: 15px;
    height: 8px;
    top:87px;
  }

  .description4-bg {
    width: calc( 100% - 60px );
    left: 15px;
    height: 8px;
    top:97px;
  }

  .description5-bg {
    width: calc( 100% - 35px );
    left: 15px;
    height: 8px;
    top:107px;
  }

}
.col-load-bordered {
  @include desktop {
     &:nth-child(3n+1):after {
        content: "";
        height: 1px;
        background-color: rgba(0,0,0,.15);
        width: 860px;
        position: absolute;
        bottom: 0;
        left: 10px;
      }
      margin-bottom: 30px;

      .grid-load {
        margin-bottom: 30px;
      }
    }
}
.col-load-full {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.grid-load-with-text {
    height: 355px;
    @include tablet {
      height: 425px;
    }

    @include mobile-xl-lg {
      height:490px;
    }

    @include mobile-sm-xs {
       height: 400px;
    }

  }

  .grid-load-with-title {
    height: 250px;

     @include tablet {
      height: 320px;
    }

    @include mobile-xl-lg {
      height:380px;
    }

    @include mobile-sm-xs {
       height: 275px;
    }
    
}

.grid-load-with-text, .grid-load-with-title {
   .img-bg {
    width: 100%;
    height: 175px;
    @include tablet {
      height: 245px;
    }
    @include mobile-xl-lg {
        height:300px;
      }

      @include mobile-sm-xs {
        height: 200px;
      }
  }
}

.grid-load-full-width {
  margin-bottom: 0;
  height: 320px;

  .img-bg {
    height: 320px;
    @include box-shadow(inset 0 -5px 5px -5px rgb(221,221,221));
  }
}

.col-load-search {
  .grid-load {
    box-shadow: none;
    margin-bottom: 0;
    @include no-mobile {
       height: 130px;
    }
   

    .img-bg {
      @include no-mobile {
         width:200px;
          height: 130px;
        float: left;
      }
     
    }

    .info-bg{
      @include no-mobile {
          margin-left: 200px;
      }
    
      @include no-mobile {
        .title-bg {
              top:5px;
              left:20px;
            }
            .author-bg {
              top:15px;
              left:20px;
            }
            .description1-bg {
              top:30px;
              left:20px;
            }
            .description2-bg {
              top:40px;
              left:20px;
            }
            .description3-bg {
              top:50px;
              left:20px;
            }
            .description4-bg {
              top:60px;
              left:20px;
            }
            .description5-bg {
              top:70px;
              left:20px;
            }
      }
            
    }
  }
  
}

.col-load-gallery {
  .grid-load {
    margin-bottom: 0;
    height: 300px;
    @include mobile-xl-lg {
        height: 400px;
      }
       @include mobile-sm-xs {
        height: 500px;
      }
    .img-bg {
      height: 300px;
      @include mobile-xl-lg {
        height: 400px;
      }
       @include mobile-sm-xs {
        height: 500px;
      }

    }
  }
 
}