@mixin border-radius($radius) {  
  border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -webkit-border-radius: $radius;

}

@mixin transition($speed) { 
  transition: all $speed ease;        
  -moz-transition: all $speed ease;                 
  -o-transition: all $speed ease;   
  -ms-transition: all $speed ease;          
 -webkit-transition: all $speed ease;   
}

@mixin transition-mix($property: all, $duration: 0.2s, $timing: linear, $delay: 0s) {
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $timing;
  transition-delay: $delay;
}

@mixin transform($param) {
  transform: $param;
  -moz-transform: $param;
  -o-transform: $param;
  -ms-transform: $param; /* IE 9 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1.5, M12=0, M21=0, M22=1.5, SizingMethod='auto expand')"; /* IE8 */
   filter: progid:DXImageTransform.Microsoft.Matrix(M11=1.5, M12=0, M21=0, M22=1.5, SizingMethod='auto expand'); /* IE6 and 7 */ 
  -webkit-transform: $param;
}

@mixin rotate($param) {  
  transform: rotate($param);
  -moz-transform: rotate($param);
  -o-transform: rotate($param);
  -ms-transform: rotate($param); 
  -webkit-transform: rotate($param);
  
}

@mixin box-shadow($params) {
  box-shadow: $params;
  -moz-box-shadow: $params;
  -webkit-box-shadow: $params;
  
}

@mixin box-shadow2($params1, $params2) {
  box-shadow: $params1, $params2;
  -moz-box-shadow: $params1, $params2;
  -webkit-box-shadow: $params1, $params2;
  
}

@mixin box-shadow3($params1, $params2, $params3) {  
  box-shadow: $params1, $params2, $params3; 
   -moz-box-shadow: $params1, $params2, $params3;  
   -webkit-box-shadow: $params1, $params2, $params3; 

 
} 

@mixin linear-gradient($color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: linear-gradient(to bottom, $color-stops);
  background: -moz-linear-gradient(top, $color-stops);
  background: -o-linear-gradient(top, $color-stops);
  background: -ms-linear-gradient(top, $color-stops);
  background: -webkit-linear-gradient(top, $color-stops);
}

@mixin linear-gradient-horizontal($color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: linear-gradient(to right, $color-stops);
  background: -moz-linear-gradient(left, $color-stops);
  background: -o-linear-gradient(left, $color-stops);
  background: -ms-linear-gradient(left, $color-stops);
  background: -webkit-linear-gradient(left, $color-stops);
}

@mixin gradient-overlay($color-stops...) {
  position:relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: nth(nth($color-stops, 1), 1);
    background: linear-gradient(to bottom, $color-stops);
    background: -moz-linear-gradient(top, $color-stops);
    background: -o-linear-gradient(top, $color-stops);
    background: -ms-linear-gradient(top, $color-stops);
    background: -webkit-linear-gradient(top, $color-stops);
  }
}

@mixin text-line($number) {
      text-overflow: ellipsis;
    -webkit-line-clamp: $number;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin column( $number ){ 
  column-count: $number;
  -moz-column-count: $number; /* Firefox */
  -webkit-column-count: $number; /* Chrome, Safari, Opera */
   
}

@mixin object-bg($width, $height) {
    width: $width;
    height: $height;
    position: relative;
    display: flex;
    flex-direction: column;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    } 
}

@mixin img-center($width, $height) {
    width: $width;
    height: $height;
    position: relative;

    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
}

@mixin bg-center($height) {
  width:100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: $height;
}

@mixin scale-img($x,$y) {
  transform: scale($x, $y);
  -ms-transform: scale($x, $y);
  -webkit-transform: scale($x, $y);
}

// responsive
@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content; /* max 767px*/
  }
}

@mixin mobile-xl {
  @media (min-width: #{$mobile-xl-max}) and (max-width: #{$mobile-width }) {
    @content; /* min 600px -max 767px */
  }
}

@mixin mobile-lg {
  @media (min-width: #{$mobile-lg-max}) and (max-width: #{$mobile-xl-min }) {
    @content; /* min 480px -max 599px */
  }
}

@mixin mobile-sm {
  @media (min-width: #{$mobile-sm-min}) and (max-width: #{$mobile-lg-min }) {
    @content; /* min 321px -max 479px */
  }
}
@mixin mobile-xs {
  @media (max-width: #{$mobile-xs}) {
    @content; /* max 320px */
  }
}

@mixin mobile-xl-lg {
  @media (min-width: #{$mobile-lg-max}) and (max-width: #{$mobile-width}) {
    @content; /*min 480px- max 767px */
  }
}

@mixin mobile-sm-xs {
  @media (max-width: #{$mobile-lg-min}) {
    @content; /* max 479px */
  }
}


@mixin mobile-portrait {
  @media (max-width: #{$mobile-width}) and (orientation : portrait) {
    @content;  /* max 767px*/
  }
}

@mixin mobile-landscape {
  @media (max-width: #{$mobile-width}) and (orientation : landscape) {
    @content;  /* max 767px*/
  }
}

@mixin tablet-landscape {
  @media (min-width: #{$tablet-width-min}) and (max-width: #{$tablet-landscape}) {
    @content; /* min 768 - max 1024px */
  }
}

@mixin desktop-menu {
   @media (min-width: #{$desktop-menu}) {
    @content; /* min 1025px */
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width-min}) and (max-width: #{$tablet-width-max }) {
    @content; /* min 768px -max 991px */
  }
}

@mixin tablet-mobile {
  @media (max-width: #{$tablet-width-max}) {
    @content; /* max 991px */
  }
}
@mixin tablet-landscape-mobile {
  @media (max-width: #{$tablet-landscape}) {
    @content; /* max 1024px */
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-xs-min}) {
    @content; /* min 992px */
  }
}

@mixin desktop-xs {
  @media (min-width: #{$desktop-xs-min}) and (max-width: #{$desktop-xs-max}) {
    @content; /* min 992px -max 1279px */
  }
}

@mixin desktop-sm {
  @media (min-width: #{$desktop-sm-min}) and (max-width: #{$desktop-sm-max}) {
    @content; /*min 1280px - max 1366 */
  }
}

@mixin desktop-md {
  @media (min-width: #{$desktop-md-min}) and (max-width: #{$desktop-md-max}) {
    @content; /*min 1367px - max 1439 */
  }
}

@mixin desktop-lg {
  @media (min-width: #{$desktop-lg-min}) and (max-width: #{$desktop-lg-max}) {
    @content; /*min 1440px - max 1599 */
  }
}

@mixin desktop-xl {
  @media (min-width: #{$desktop-xl-min}) and (max-width: #{$desktop-xl-max}) {
    @content; /*min 1600px - max 1919 */
  }
}

@mixin desktop-xxl {
  @media (min-width: #{$desktop-xxl-min}) {
    @content; /*min 1920px */
  }
}

@mixin no-mobile {
  @media (min-width: #{$tablet-width-min}) {
    @content; /* min 768px */
  }
}

@mixin no-desktop {
  @media (max-width: #{$tablet-width-max}) {
    @content; /* max 991px */
  }
}

@mixin absolute-center {
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%); 
  -moz-transform: translate(-50%, -50%); 
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

@mixin vertical-center {
  position:absolute;
  top:50%; 
  transform: translateY(-50%); 
  -moz-transform: translateY(-50%); 
  -ms-transform: translateY(-50%); 
  -o-transform: translateY(-50%);  
  -webkit-transform: translateY(-50%);
   
}

@mixin horizontal-center {
  position:absolute;
  left:50%; 
  transform: translateX(-50%); 
  -moz-transform: translateX(-50%); 
  -ms-transform: translateX(-50%); 
  -o-transform: translateX(-50%); 
  -webkit-transform: translateX(-50%); 
   
}

@mixin abs($left, $top){
    position: absolute; left: $left; top: $top;
}

@mixin absr($right, $top){
    position: absolute; right: $right; top: $top;
}

@mixin absbl($bottom, $left){
    position: absolute; bottom: $bottom; left: $left;
}

@mixin absbr($bottom, $right){
    position: absolute; bottom: $bottom; right: $right;
}

@mixin rotate($params) {
  transform: rotate($params);
  -ms-transform: rotate($params);
  -webkit-transform: rotate($params);
  
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
  ::-webkit-scrollbar {
      width:  $size;
      height: $size;
  }

  ::-webkit-scrollbar-thumb {
      background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
      background: $background-color;
  }
}

@mixin placeholder { 
    &::placeholder { @content } 
    &::-webkit-input-placeholder { @content }
    &::-moz-placeholder { @content }
    &::-moz-placeholder {  @content }
    &::-ms-input-placeholder { @content }
   
}