.search-title {
	h1 {

		float:left;
	}
}
.search-input-group {
	width:175px;
	float:right;
	padding:6px 0;

	.search-input {
		@include border-radius(0);
		border:1px solid $gray-dark;
		box-shadow:none;
		height:30px;
	}

	.search-btn {
		display: block;
	    width: 30px;
	    height: 30px;
	    min-width:auto;
	    border: 1px solid #232323;
	    background-image: url(../../dist/images/icons/search-white.svg);
	    background-repeat: no-repeat;
	    background-size: 12px 12px;
	    background-position: 50% 50%;
	    @include border-radius(0);
	    background-color:$gray-dark;

	    &:hover {
			background-image: url(../../dist/images/icons/search-yellow.svg);
		}
	}
}

.search-row {
	border-bottom:1px solid rgba($black,0.15);
	padding-bottom:20px;

	&:not(:first-child) {
		padding-top:20px;
	}

	.search-item {

		.search-img {
			width:200px;
			float:left;

			@include mobile {
				float:none;
				width:100%;
				margin-bottom:10px;
			}

			.img-holder {
				@include bg-center(130px);

				@include mobile {
					@include bg-center(185px);
				}
			}
		}
		.search-img + .search-info {
			margin-left:200px;
			padding-left:20px;

			@include mobile {
				margin-left:0;
				padding-left:0;
			}
		}
		.search-info {
			.item-heading-with-subtitle {
       			height:auto;
        		border:none;
        		padding:4px 0;
 
		        .item-title {
		          margin:0 0 10px 0;
		          height: auto;
		 
		        }
      		}
 
			.search-text {
				@include text-line(4);
				max-height: 70px;
	
			}
		}
	}
}