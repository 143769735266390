.page-title {
	padding:35px;

	h1 {
		font-size:$font-title;
		line-height:17px;
		text-transform:uppercase;
		color:$gray-dark;
		border-left:3px solid $yellow;
		padding:10px 0 10px 20px;
		margin-top:0;
		margin-bottom:0;
	}
}

.page-title-with-subtitle {
	margin:35px;
	border-left:3px solid $yellow;

	h1 {
		font-size:$font-title;
		line-height: 17px;
		text-transform:uppercase;
		color:$gray-dark;
		padding-left:20px;
		margin-top:0;
		margin-bottom:10px;
	}
	.subtitle {
		padding-left:20px;
		font-size:$font-xs;
		line-height: 12px;
		span + span {
			margin-left:3px;
			position: relative;
			padding-left:6px;

			&:before {
				content: '|';
				position: absolute;
				left:0;
			}
		}
	}
}
.school-title {
	margin-top:30px;
	margin-bottom:30px;
	padding-left:35px;

	@include mobile {
		padding-left:0;
	}

	.icon {
		width:42px;
		height:42px;
		background-color:$yellow;
		background-size:50% 50%;
		float:left;
	}

	h1 {
		padding-left:62px;
		margin-top:0;
		margin-bottom:0;
		font-size:$font-title;
		line-height:17px;
		padding-top:5px;
		color:$gray-dark;
		text-transform:uppercase;

	}
}

.school-title-with-subtitle {
	margin-top:30px;
	margin-bottom:30px;
	padding-left:35px;

	@include mobile {
		padding-left:0;
	}
	.icon {
		width:42px;
		height:42px;
		background-color:$yellow;
		background-size:50% 50%;
		float:left;
	}

	h1.title {
		margin-top:0;
		margin-bottom:8px;
		font-size:$font-title;
		line-height:17px;
		color:$gray-dark;
		text-transform:uppercase;
		padding-left: 62px;
	}

	.subtitle {
		font-size:$font-xs;
		line-height:12px;
		padding-left: 62px;
		
		span + span {
			position:relative;
			margin-left:4px;
			padding-left:5px;

			&:before {
				content:'|';
				position:absolute;
				left:0;
			}
		}
	}
}
.container-small {
	.page-title {
		@include mobile {
			padding:35px 0;
		}
	}
	.page-title-with-subtitle {
		@include mobile {
			margin:35px 0;
		}
	}
}

.btn {
	@include border-radius(0);
	background-color:transparent;
	color:$white;
	font-size:$font-md;
	line-height:14px;
	text-transform:uppercase;
	padding:12px 15px;
	min-width:100px;
	border:1px solid $white;

	&.btn-dark {
		background-color:$gray-dark;
		border-color:$gray-dark;
	}
	&:hover {
		background-color:$yellow;
		border-color:$yellow;
		color:$white;
	}
	&:focus, &:active, &:hover {
		text-decoration:none;
		box-shadow:none;
		outline:none;
		color:$white;
	}
}
.show-more-btn {
	display:block;
	margin-bottom:20px;
	font-size:$font-xs;
	line-height:12px;
	color:$yellow;
	text-transform:uppercase;

	&:hover, &:focus {
		text-decoration:none;
		color:$yellow;
	}
}

.bg-cover {
	min-height:100vh;
	background-size:cover;
	background-repeat: no-repeat;
	background-position: center top;
	width:100%; 
	height: 100%;
	@include gradient-overlay(rgba(255,255,255,0.34),rgba(255,255,255,0));
	
}
.icon {
	display: inline-block;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.photo-icon {
	 background-image:url(../../dist/images/icons/photo-camera.svg);
	 width: 20px;
	 height: 15px;
}
.video-icon {
	 background-image:url(../../dist/images/icons/video-camera.svg);
	 width: 20px;
	 height: 15px;
}
.article-icon {
	 background-image:url(../../dist/images/icons/article.svg);
	 width: 20px;
	 height: 15px;
}
.video-holder {
	margin:30px 0;
}

.item-row {
	@include no-mobile {
		border-bottom: 1px solid rgba($black, 0.15);
	}
	padding-bottom: 10px;
	&:not(:first-child) {
		padding-top: 30px;
	}
}
.item-heading-box {
	padding:10px 10px 10px 0;
	margin-left: 10px;
}
.item-heading {
	height:42px;
	border-left:3px solid $yellow;

	a:hover, a:focus, a:active {
		text-decoration: none;
	}

	.item-title {
		padding:2px 10px;
		font-size:$font-title;
		line-height: 17px;
		color: $gray-dark;
		margin:0;
		@include text-line(2);
		max-height: 36px;
	}
}

.item-heading-with-subtitle-box {
	padding:10px 10px 10px 0;
	margin-left: 10px;
}

.item-heading-with-subtitle {
	height:65px;
	border-left:3px solid $yellow;
	padding:4px 10px;

	a:hover, a:focus, a:active {
		text-decoration: none;
	}

	.item-title {
		font-size:$font-title;
		line-height: 17px;
		color: $gray-dark;
		margin:0 0 4px 0;
		@include text-line(2);
		max-height: 36px;
		padding:2px 0;
	}
}

.item-heading-with-subtitle-absolute {
	margin:10px;
	@include transition (0.3s);
}

.item-subtitle {
	color:$gray;
	font-size:$font-xs;
	line-height: 12px;
	span:not(.icon) + span {
		margin-left:3px;
		&:before {
			content: '|';
			margin-right:3px;
		}
	}
}

.item-col-bordered {
	

	@include desktop {
		margin-bottom: 30px;

		&:nth-child(3n+1) {
		
				&:after {
				content: '';
				height: 1px;
				background-color: rgba($black, 0.15);
				width: 860px;
				position: absolute;
				bottom:0;
				left:10px;

			}
		}
	}

	@include tablet {
		&:nth-child(3n+1) {
			&:after {
				width: 750px;
			}
		}
	}
}


.item-col {

	.item-box {
		margin-bottom: 20px;
		position:relative;
		@include transition (0.3s);

		&:hover {
			@include box-shadow(2px -1px 15px 4px rgba(0,0,0,0.15));

			.item-img {
		
				.img-holder {		
					@include transform(scale(1.2));
				}
			}

			.item-heading-box {
				border-left: 3px solid $yellow;
				.item-heading {
					border:none;
				}
			}

			.item-heading-with-subtitle-box {
				border-left: 3px solid $yellow;
				.item-heading-with-subtitle {
					border:none;
				}
			}

			.item-heading-with-subtitle-absolute {
				height: auto;
				margin-top: 0;
				margin-bottom: 0;
				padding-top: 14px;
				padding-bottom: 14px;
			}

			.item-text {
				border-color: $yellow;
			}
			
			
		}
	}
	.item-img {
		width:100%;
		position: relative;
		overflow:hidden;

		.img-holder {
			@include transition(0.3s);
			@include bg-center(175px);
			@include tablet {
				@include bg-center(245px);
			}

			@include mobile-xl {
				@include bg-center(300px);
			}

			@include mobile-lg {
				@include bg-center(250px);
			}

			@include mobile-sm {
				@include bg-center(200px);
			}

			@include mobile-xs {
				@include bg-center(185px);
			}
		}
	}
}

.item-col, .item-col-bordered {
	a {
		display: block;
		font-family: inherit !important;
		font-weight: normal !important;
	}

	a, a:focus, a:active, a:visited {
		text-decoration: none !important;
		color: inherit;
	}

	&:hover {
		a, a:focus, a:active, a:visited {
			text-decoration: none !important;
			color: inherit;
		}
	}
		
}

.item-subtitle-absolute {
	position: absolute;
	bottom:10px;
	left:10px;
	color:$white;

	.icon {
		margin-right: 10px;
	}
}

.item-text {
	
	@include transition(0.3s);
	border-left: 3px solid transparent;
	margin-left: 10px;
	padding-bottom: 10px;

	p {
		height: 86px;
		@include text-line(5);
		padding: 0 10px 0 14px;
		margin:0;
		
	}
	
}

.full-width-block {

	.full-width-col {
		position: relative;
		padding-left: 0;
		padding-right: 0;
		overflow: hidden;

		.full-width-img {
			width:100%;

			.img-holder {
				@include bg-center(320px);
				@include transition(0.3s);
				@include gradient-overlay(rgba(0,0,0,0.34),rgba(255,255,255,0));

				&:hover {
					@include transform(scale(1.2));
				}
			}

		}

		.full-width-info {
			position: absolute;
			top:30px;
			left:30px;
			@include tablet-mobile {
				left:20px;
			}

			a {
				&:hover, &:focus, &:active {
					text-decoration:none;
				}
			}

			.full-width-title {
				width:174px;
				color: $white;
				font-size:$font-lg;
				line-height: 24px;
				margin-bottom: 25px;
				@include text-line(3);
				max-height: 72px;

			}

			.full-width-subtitle {
				width: 260px;
				color: $white;
				font-size:$font-sm;
				line-height: 20px;
				@include text-line(6);
				max-height: 120px;

				@include tablet-mobile {
					width: calc(100% - 20px);
				}

			}

		}
	}
}	

.owl-carousel {
	.owl-nav {
		 .disabled{
    		display: none;
  		}
  		
		.owl-prev, .owl-next {
			position:absolute;
			top:50%;

			.slider-arrow {
				display:block;
				border:1px solid $gray-dark;
				background-color:$white;
				width:35px;
				height:35px;
				background-image:url(../../dist/images/icons/arrow.svg);
				background-repeat:no-repeat;
				background-position:50% 50%;
				background-size:50% 50%;
				
				&:hover {
					background-image:url(../../dist/images/icons/arrow-white.svg);
					background-color:$yellow;
					border-color:$yellow;
				}
			}

		}

		.owl-prev {
			left:0;
			margin-left:-55px;

			@include tablet-mobile {
				margin-left:0;
			}

			.slider-arrow {
				@include rotate(90deg);

				&:hover {
					@include rotate(270deg);
				}
			}
		}
		.owl-next {
			right:0;
			margin-right:-55px;

			@include tablet-mobile {
				margin-right:0;
			}

			.slider-arrow {
				@include rotate(270deg);

				&:hover {
					@include rotate(90deg);
				}
			}
		}
		
	}
}
.expeditions-slider {
	width: 100%;

	.slider-item {
		.img-holder {
			@include bg-center(350px);

			@include mobile-xl-lg {
				@include bg-center(300px);
			}
			@include mobile-sm {
				@include bg-center(250px);
			}
			@include mobile-xs {
				@include bg-center(200px);
			}
		}
	}
	
}
.expeditions-item {

	.expeditions-img {
		width: 100%;

		.img-holder {
			@include bg-center(190px);
			@include gradient-overlay(rgba(255,255,255,0),rgba(0,0,0,0.34));
			@include transition(0.3s);

			@include tablet {
				@include bg-center(245px);
			}

			@include mobile-xl {
				@include bg-center(300px);
			}

			@include mobile-lg {
				@include bg-center(250px);
			}

			@include mobile-sm {
				@include bg-center(200px);
			}

			@include mobile-xs {
				@include bg-center(185px);
			}
		}
	}

	.item-heading-with-subtitle {
		position: absolute;
		bottom:0;
		height: auto;

		.item-title, .item-subtitle {
			color: $white;
			height: auto;
		}
	}

	&:hover {
		.item-heading-with-subtitle {
			height: auto;
		}
	}
}

.class-block {
	padding: 0 35px;
	margin-bottom: 10px;

	.show-more-btn {
		display:none;

		@include mobile {
			display:block;
		}
	}

	.row, .class-row {
		margin-left: -2px;
		margin-right: -2px;
	}

	.class-col {
		padding-left: 2px;
		padding-right: 2px;
	}

	.class-title {
		font-size:$font-title;
		text-transform: uppercase;
		color:$gray-dark;
		margin-top: 0;
		margin-bottom: 20px;
	}

	.class-list {
		margin-bottom: 0;
		li {
			font-size:$font-xs;
			line-height: 14px;
			height: 30px;
			
			a {
				color: $gray-dark;
				font-weight: bold;
				font-size: $font-md;

				&:hover, &:focus, &:active {
					text-decoration: none;
					color: $yellow;
				}
			}

			span {
				display:inline-block;
				color: $gray;
				position:relative;
				margin-left:3px;
				padding-left:6px;

				&:before {
					content:'-';
					position:absolute;
					left:0;
				}
			}
		}
	}
}

.works-block, .gallery-block {
	.item-col {
		.img-holder {
			@include gradient-overlay(rgba(255,255,255,0),rgba(0,0,0,0.34));
		}
	}
}