.images-grid {

		.img-grid-row {

			&>.row {
				
				&>.img-grid-col {
					margin-bottom: 20px;

					.img-grid-item {
						width:100%;
						cursor: pointer;

						.img-holder {
							@include bg-center(160px);
						}
					}

					@include mobile {
						margin-bottom: 10px;
					}
			
					@include mobile-xl-lg {
						width:50%;
						float: left;
						padding-left: 5px;
						padding-right: 5px;
					}

					@include mobile-sm-xs {
						padding-left: 0;
						padding-right: 0;
					}

				
					&>.row {
						@include mobile {
							margin-left: -5px;
							margin-right: -5px; 
						}

						
						.img-grid-col {

							@include mobile {
								width: 50%;
								float: left;
								padding-left: 5px;
								padding-right: 5px; 
							}

						}

					}

				}
			}

			.row {
				@include mobile-xl-lg {
					&:after, &:before {
						display: inline;
					}
				}
				
			}
		}

}

.trainers-grid {
	margin:30px 0;

	.img-grid-row {
		margin-bottom: 0;

		.row {
			margin-left:-5px;
			margin-right: -5px;
			&:before, &:after {
				@include mobile {
					display: inline;

				}
			}
		}

		.img-grid-col {
			@include mobile {
				padding-left: 5px;
				padding-right: 5px;
				margin-bottom: 10px;
			}

			@include mobile-xl-lg {
				width: 50%;
				float: left;
			}

			.img-grid-item {
				width:100%;
				position:relative;
				@include transition (0.3s);
				cursor: pointer;


			.img-holder {
				@include gradient-overlay(rgba(0,0,0,0.34),rgba(255,255,255,0));
				@include bg-center(300px);

				@include mobile-xl-lg {
					@include bg-center(400px);
				}

				@include mobile-sm-xs {
					@include bg-center(500px);
				}
			}

			.trainer-name {
				text-transform:uppercase;
				color:$white;
				font-size:$font-title;
				line-height:25px;
				position:absolute;
				top:20px;
				left:20px;
				@include transition (0.3s);

				&:after {
					@include transition (0.3s);
					content:'';
				}
				
			
			}

			&:hover {
				@include box-shadow(2px -1px 15px 4px rgba(0,0,0,0.15));

				.trainer-name {
					&:after {
						content:'';
						position:absolute;
						width:33px;
						height:3px;
						background-color:$yellow;
						margin-top:15px;
						left:0;
						top:100%;

					}
				}
			}
		}

		&.img-grid-col-divided {

			.img-grid-item:first-child {
		
				margin-bottom: 20px;

				@include mobile {
					margin-bottom: 10px;
				}

			}
			.img-holder {
					@include bg-center(140px);

					@include mobile-xl-lg {
						@include bg-center(195px);
					}

					@include mobile-sm-xs {
						@include bg-center(500px);
					}
				}
		}

		&.img-grid-col-4 {
			.img-holder {
				@include bg-center(340px);
			}
			}
		}

		
	}
}
.gallery-block {
  	background-color: rgba(0,0,0,0.54);
    position: fixed;
    top: 90px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
	display: none;

    @include tablet-mobile {
    	top:60px;
    }

	.close-gallery {
		display: block;
		width:18px;
		height:18px;
		position: absolute;
		top:50px;
		right:50px;
		background-image: url(../../dist/images/icons/close-white.svg);

		@include tablet-mobile {
			top:20px;
			right:20px;
		}
	}
    .gallery-slider {
    	@include absolute-center();
    	.owl-stage {
    		display: flex;
    		align-items: center;
    		display: -webkit-flex;
    		-webkit-align-items: center;
    		
    		.owl-item {
    			visibility: hidden;

    			&.active {
    				visibility: visible;
    			}
    		}
    	}

    	@include desktop {
    		width: 870px;

    	}
    	
    	@include transition(0.3s);

    	@include tablet {
    		width: 600px;
    	}

    	@include mobile {
    		width:100%;
    	}
    	

		.slider-item {
			@include desktop {
				max-width: 870px;
				max-height: 580px;
			}

			@include desktop-sm {
				max-height: 450px;
			}
			
			@include desktop-xs {
				max-height: 450px;
			}
			
			@include tablet {
				max-width: 600px;

			}
			
			@include mobile {
				max-width: 100%;
			}
			
			&.landscape {
				img {
					width: 100%;
					height: auto;
					margin:0 auto;
				}

				@include mobile {
					width: 100%;
				}
			}
			
			&.portrait {
				 img {
					width: auto;
					height: 100%;
					margin:0 auto;
				 }

				 @include desktop {
					height: 580px;
				}

				@include desktop-sm {
					height: 450px;
				}
				@include desktop-xs {
					height: 450px;
				}

				@include tablet {
					height: 450px;
				}

				@include mobile {
					height: 450px;
				}
				
				@include mobile-sm {
					height: 350px;
				}

				@include mobile-xs {
					height: 300px;
				}
				
			}
		}
		
		.owl-nav {
			 .disabled{
    			display: none;
  			}
  			@include mobile {
  				display: none;
  			}
			.owl-next,.owl-prev {
			  	.slider-arrow {
			  		width: 40px;
					height: 40px;
					background-color: rgba($black, 0.05);
			  		background-size:20px auto;
					background-position: 50% 50%;
			  		background-repeat: no-repeat;
			  		border: none;
					&:hover {
						background-color: rgba($black, 0.1);
						background-image: url(../../dist/images/icons/arrow-white.svg);
					}
			  	}
		  	}

		  	.owl-next {
		  		margin-right:-60px;

		  		.slider-arrow {
		  			background-image: url(../../dist/images/icons/arrow-white.svg);
		  			@include rotate(90deg);
		  		}
		  	}

		  	.owl-prev {
		  		margin-left:-60px;

		  		.slider-arrow {
		  			background-image: url(../../dist/images/icons/arrow-white.svg);
		  			@include rotate(270deg);
		  		}
		  	}
		}
		  
    }

	.carousel-content {
		display: none;
	}
	
}

.trainers-gallery-block {
	@include mobile {
		display: none !important;
	}
}

