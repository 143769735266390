/************************************* Mobile menu earlier ********************************************/
@include tablet-landscape {
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
    }
  
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-collapse.collapse.in {
        display: block!important;
    }

    .navbar-nav {
        float: none!important;
        margin: 0;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}


header {
	background-color:$white;
	position: fixed;
	width:100%;
	z-index: 5;

	@include desktop-menu {
		height:90px;
		@include box-shadow(0 7px 10px -7px rgba(0,0,0,0.12));
	}

	.main-navbar {
		border:none;
		@include border-radius(0);
		margin-bottom:0;
		padding:0 20px;

		@include tablet-landscape-mobile {
			padding:0;
		}

		&.active-submenu {
			@include tablet-landscape-mobile {
				.main-navbar-collapse {
					left:-45%;
					@include gradient-overlay(rgba(255,255,255,0),rgba(255,255,255,1));
					top:3px;

					&:before, &:after {
						display: block;
					}
				}

				.search {
					z-index:-1;
				}

				.submenu-block {
					height: calc(100vh - 60px);
					background-color: $white;
					@include box-shadow(-10px 0 10px -10px rgba(0,0,0,0.12));
					position: absolute;
					top: 63px;
					width: 80%;
					left: 20%;
					z-index: 1;
				}

				.submenu {
					background-color: transparent;
					display: block;
					@include horizontal-center();
					top:80px;
					box-shadow: none;
					margin:0;
					padding:0;

					&>li {
						&>a {
							padding:25px 15px;

							@include mobile-xs {
								padding:15px;
							}
						}
					}
				}
				
			}
		}
		

		.menu-btn {
			@include border-radius(0);
			background-image:url(../../dist/images/icons/menu-btn.svg);
			background-repeat:no-repeat;
			width:20px;
			height:15px;
			padding: 0;
    		margin: 20px 0 0 15px;
    		float: left;

			&[aria-expanded="true"] {
				background-image:url(../../dist/images/icons/menu-close.svg);
				width:15px;
				height:15px;
			}

		
		}
		.main-navbar-header {
			@include tablet-landscape-mobile {
				@include box-shadow(0 7px 10px -7px rgba(0,0,0,0.12));
				height:60px;
			}
		}
		.main-navbar-collapse {
			padding-left:0;
			padding-right:0;
				@include tablet-landscape-mobile {
					position: relative;
					height:calc(100vh - 60px) !important;
			}
		}
		.main-menu-list {
			margin:0;
			@include tablet-landscape-mobile {
				padding-top:110px;
			}
			&>li {
				
				@include desktop-menu {

					padding:0 9px;

					&:first-child {
						padding-left:0;
					}
				}

				&>a {
					font-size:$font-xs;
					line-height:90px;
					color: $gray-dark;
					text-transform:uppercase;
					padding:0;
					@include transition(0.3s);

					@include tablet-landscape-mobile {
						line-height:12px;
					}


					&:hover, &:focus {
						background-color: transparent;
					}
				}

				&.open {
					background-color:transparent;

					&>a, &>a:hover, &:focus {
						background-color:transparent;
						box-shadow:none;
					}

				}

				&:hover, &.active {
					&>a {
						@include desktop-menu {
							color:$yellow;
							@include box-shadow(inset 0 -3px 0 0 $yellow);
						}
						
					}
				}
			}

			&>li.dropdown {
				
				@include desktop-menu {
					&:hover {

						&>a.dropdown-toggle {
					    	box-shadow:none;
					    }
						.dropdown-menu {
					    	display: block;
						}
					}
				}

				&>a.dropdown-toggle {
					display:inline-block;
					position:relative;

					@include tablet-landscape-mobile {
						&:after {
							content:'';
							background-image: url(../../dist/images/icons/arrow.svg);
							background-repeat:no-repeat;
							background-position:50% 50%;
							@include rotate(270deg);
							width:10px;
							height:12px;
							position:absolute;
							left:100%;
							margin-left:5px;

						}
					}

					@include tablet-landscape-mobile {
						&[aria-expanded="true"] {
							color:$yellow;
						
							&:after {
								background-image: url(../../dist/images/icons/arrow-yellow.svg);
							}
						
						}
					}

					
				}

				&>.submenu {
					@include tablet-landscape-mobile {
						display: none !important;
					}
				}
				
			}

			@include tablet-landscape-mobile {
				text-align:center;

				&>li {
					margin-bottom:40px;

					@include mobile-xs {
						margin-bottom: 20px;
					}
				}
			}	
		}

		.submenu {
			
			background-color:$white;
			border:none;
			@include box-shadow2(0 5px 10px rgba(0,0,0,0.15), inset 0 7px 9px -7px rgba(0,0,0,0.15));
			margin:0;
			@include border-radius(0);
			
			@include desktop-menu {
				display:none;
				padding:5px 0;
				width:130px;
				left:50%;
				transform:translateX(-50%);
				border-bottom: 3px solid $yellow;
			}

			&>li {
				&>a {
					font-size:$font-xs;
					padding:10px 20px;
					text-align:center;
					color:$gray-dark;
					text-transform:uppercase;
					white-space: normal;

					&:hover, &:focus {
						background-color: transparent;
					}
					&:hover {
						color:$yellow;
					}
				}
			}
		}

		.logo {
			position:absolute;
			top:10px;
			@include horizontal-center();
			img {
				height:30px;
				width:auto;
			}
			@include desktop-menu {
				width:200px;
				@include absolute-center();
				img {
					width:100%;
					height:auto;
				}
			}
			
		}
		
		.main-navbar-right {
		    padding-top:30px;
		    float:right;

		    @include tablet-landscape-mobile {
		    	float:none;
		    	padding-top:0;
		    }

			.languages-dropdown {
				float:left;
			
				margin-left:20px;
				margin-right:30px;
	
				a.dropdown-toggle {
					width:55px;
				}
				.languages-list {
					width:55px;
				}

				@include tablet-landscape-mobile {
					display:none;
				}
			}

			.mobile-languages {
				width:100%;
				margin-top: 40px;

				@include desktop-menu {
					display:none;
				}
				.mobile-languages-list {
					margin:0;
					text-align:center;

					&>li {
						padding-left:10px;
						padding-right:10px;

						&>a {
							font-size:$font-xs;
							line-height:12px;
							color:$gray-dark;
							text-transform:uppercase;
							border:1px solid $gray-dark;
							padding:10px 20px;

							&:hover, &:focus {
								text-decoration:none;
							}
						}

						&.active {
							
							&>a {
								background-color:$gray-dark;
								color:$white;
							}
						}
					}
				}
			}

			.social-list {
				float:left;
				margin-left:0;
				margin-bottom:0;
				padding:5px 0;

				@include tablet-landscape-mobile {
					float:none;
					text-align:center;
					padding:0;
				}

				li {
					padding-left:20px;
					padding-right:0;

					@include tablet-landscape-mobile {
						padding-left:15px;
						padding-right:15px;
					}

					a {
						.social-icon {
							display:block;
							// background-repeat:no-repeat;
							// background-position:50% 50%;
							// width:20px;
							// height:20px;

							font-size: 20px;
							color: $gray-dark;

							&:hover {
								color: $yellow;
							}
							
						}

						// .fb-icon {
						// 	background-image:url(../../dist/images/icons/facebook.svg);
						// }
						// .youtube-icon {
						// 	background-image:url(../../dist/images/icons/youtube.svg);
						// }
						// .vimeo-icon {
						// 	background-image:url(../../dist/images/icons/vimeo.svg);
						// }

						// &:hover {
						// 	.fb-icon {
						// 		background-image:url(../../dist/images/icons/facebook-yellow.svg);
						// 	}
						// 	.youtube-icon {
						// 		background-image:url(../../dist/images/icons/youtube-yellow.svg);
						// 	}
						// 	.vimeo-icon {
						// 		background-image:url(../../dist/images/icons/vimeo-yellow.svg);
						// 	}
						// }
					}
				}

			}
		}
		.search {
			float:left;
			@include tablet-landscape-mobile {
				float: none;
			    position: absolute;
			    top: 20px;
			    border-bottom: 3px solid $gray-dark;
			    padding: 0 20px 20px 20px;
			    width: 100%;
			    left:0;
			}

			.search-input-group { 
				padding:0;
				width: 175px;
			    height: 30px;
			    position: relative;

			    @include tablet-landscape-mobile {
			    	float:none;
			    	width:100%;
			    }

				input {  
					position:absolute;
			        width:0px;
			        z-index:0;
			        margin-left:145px;
			        @include transition(1s);
			        height:30px; 
			        outline: none; 

			        @include tablet-landscape-mobile {
			        	width:100%;
			        	margin-left:0;
			        }
			        @include desktop-menu {
			        	&:focus {
			    			width: 145px;
			    		 	margin-left: 0px;

			    		 & + .input-group-btn {
			    		 	.search-btn {
			    		 		background-image:url(../../dist/images/icons/search-white.svg);
								background-color:$gray-dark;

								&:hover {
									background-image:url(../../dist/images/icons/search-yellow.svg);
								}
			    		 	}
			    		 }


			    		}
			       } 	
			    }

			    @include desktop-menu {
			       &:hover {
			    	input, input:focus {
			    		width: 145px;
			    		 margin-left: 0px;
			    	}

			    	.search-btn {
						background-image:url(../../dist/images/icons/search-white.svg);
						background-color:$gray-dark;
			    	}
			    	
			    	}
			    }

				.search-btn {  
					position: absolute;
					right:0;
					top:0;
					z-index:1;
					background-image:url(../../dist/images/icons/search.svg);
					border:1px solid $gray-dark;
					background-color:$white;

					@include tablet-landscape-mobile {
							background-image:url(../../dist/images/icons/search-white.svg);
							background-color:$gray-dark;
					}
				}

			}

		}
	}
}


header + .content {
	padding-top:50px;

	@include desktop-menu {
		padding-top: 90px;
	}

}

.school-header {
	margin-bottom: 30px;
 
    @include box-shadow3(5px 0 5px -5px rgba(0,0,0,0.15), -5px 0 5px -5px rgba(0,0,0,0.15), inset 0 -3px 0 0 $gray);
 

	@include mobile {
		@include box-shadow(inset 0 -3px 0 0 $gray);
 
	}

	.page-title {
		margin-top: 0 !important;
	}

	.school-menu {

        height: 100%;
        position:relative;
        margin-top:10px;
        padding:10px 35px 0 35px;

        @include tablet-landscape-mobile {
        	
        	overflow: auto;

        	::-webkit-scrollbar {
				display: none;
			}
        }

		@include mobile {
			padding:10px 0 0 10px;
		}
		
		.page-title {
			padding:35px;
		}

		.school-menu-list {
			list-style: none outside none;
      		white-space: nowrap;
			margin:0;

			 @include tablet-landscape-mobile {
			  	overflow:auto;
			  }

			&>li {
				padding-left:8px;
				padding-right:8px;
		        height: 26px;
		        border-bottom: 3px solid $gray;

		        @include desktop-menu {
		        	float: left;
		        }

				&>a {
					padding:0 5px 11px 5px;
					font-size:$font-xs;
					line-height: 12px;
					text-transform: uppercase;
					color:$gray-dark;

					&:hover, &:focus, &:active {
						text-decoration:none;
					}
				}
		
				&.active {
		
					border-bottom: 3px solid $yellow;
					
					&>a {
						color: $yellow;
					}
	
				}

				&:hover {
					@include desktop-menu {
						border-bottom: 3px solid $yellow;
						
						&>a {
							color: $yellow;
						}
					}
					
				}
			}
		}
	}

}
.school-header + .filters {
	padding-top:0;
}